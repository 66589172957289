export interface PaginationAction {
    type: PaginationActionType,
    totalPages: number,
    currentPage: number
}

export enum PaginationActionType {
    SET_TOTAL_PAGES = "SET_TOTAL_PAGES",
    SET_CURRENT_PAGE = "SET_CURRENT_PAGE",
}

export const onSetTotalPages = (totalPages: number) => ({type: PaginationActionType.SET_TOTAL_PAGES, totalPages})
export const onSetCurrentPage = (currentPage: number) => ({type: PaginationActionType.SET_CURRENT_PAGE, currentPage})