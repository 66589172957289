import {PosterResDto} from "../api/dto/AuthReqDto";

export interface ActionType {
    type: AuthAction;
    posts: PosterResDto[],
    currentId: number,
}

export enum AuthAction {
    SIGN_IN = "SIGN_IN",
    SIGN_OUT = "SIGN_OUT",
    OPEN_CREATE_MODAL = "OPEN_CREATE_MODAL",
    CLOSE_MODALS = "CLOSE_MODALS",
    OPEN_DELETE_MODAL = "OPEN_DELETE_MODAL",
    SET_POSTERS = "SET_POSTERS",
    SET_POSTER_ID = "SET_POSTER_ID",
}

export const signIn = () => ({type: AuthAction.SIGN_IN});
export const signOut = () => ({type: AuthAction.SIGN_OUT});
export const openCreateModal = () => ({type: AuthAction.OPEN_CREATE_MODAL});
export const openDeleteModal = () => ({type: AuthAction.OPEN_DELETE_MODAL});
export const closeModals = () => ({type: AuthAction.CLOSE_MODALS});
export const setPosters = (posts: PosterResDto[]) => ({type: AuthAction.SET_POSTERS, posts});
export const setCurrentId = (currentId: number) => ({type: AuthAction.SET_POSTER_ID, currentId});