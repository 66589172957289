import React from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../../store/reducers";
import PreloaderPlayer from "../../common/PreloaderPlayer";
import MenuNotFound from "./MenuNotFound";
import MenuProductPage from "./MenuProductPage";
import MenuSubgroupWithProduct from "./MenuSubgroupWithProduct";

const MenuProducts: React.FC = () => {

  const {loading, error, activeGroup, products} = useSelector((state: RootState) => state.menu);

  if (loading) {
    return <PreloaderPlayer style={{"height": 100,"marginTop": 265}}/>
  } else if (error) {
    return <MenuNotFound/>
  }

  return (
    <main>
      {
        activeGroup?.hasSubgroups ?
          <MenuSubgroupWithProduct parentGroup={activeGroup}/>
          :
          <>
            <div className={"title"}>{activeGroup?.name.toUpperCase()}</div>
            <MenuProductPage products={products}/>
          </>
      }
    </main>
  );
}

export default MenuProducts;