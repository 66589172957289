import {GetGroupDto} from "../api/dto/GetGroupDto";

export interface TogglerAction {
    type: TogglerActionType,
    groups: Array<GetGroupDto>
}

export enum TogglerActionType {
    SET_GROUPS = "SET_GROUPS"
}

export const onSetGroups = (groups: Array<GetGroupDto>): TogglerAction => ({
    type: TogglerActionType.SET_GROUPS,
    groups
});