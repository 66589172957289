import React from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  children: React.ReactNode
}

const Portal = (props: PortalProps) => {

  const element: HTMLElement = document.getElementById("portal") || document.createElement('div');
  element.id = 'portal';

  return ReactDOM.createPortal(<>{props.children}</>, element);
}

export default Portal;