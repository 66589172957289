class WindowUtils {

  static scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
}

export default WindowUtils;