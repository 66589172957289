import React from 'react';
import PostInputsContainer from "./PostInputsContainer";
import NoPosts from "./NoPosts";
import {PosterResDto} from "../../api/dto/AuthReqDto";

interface PostsProps {
  fetchPosters: () => void,
  posts: PosterResDto[],
}

const AdminPanel = ({posts, fetchPosters}: PostsProps) => {

  if (!posts || posts.length === 0) {
    return <NoPosts/>
  }

  return (
      <>
        {
          // fixMe проблема типизации posts возвращается не как массив
          Array.isArray(posts) &&
          posts.map((el: PosterResDto, index: number) =>
              <PostInputsContainer key={el.id}
                                   id={el.id}
                                   index={index + 1}
                                   fetchPosters={fetchPosters}
                                   poster={el}
              />
          )
        }
      </>

  );
};

export default AdminPanel;