import React from 'react';
import moment from "moment";
import PosterItem from "./PosterItem";

interface PosterItemContainerProps {
  setIsPostChosen: any;
  setTouchedPost: any;
  description: string;
  date: string;
  id: number;
  index: number;
  imageUrl: string;
  title: string;
  url: string;
  currentPost: number;
  touchedPost: number;
  isPostChosen: boolean;
}

const PosterItemContainer = ({
                               setIsPostChosen,
                               setTouchedPost,
                               date,
                               description,
                               id,
                               imageUrl,
                               index,
                               title,
                               url,
                               currentPost,
                               touchedPost,
                               isPostChosen
                             }: PosterItemContainerProps) => {

  const monthsArray = [
    "ЯНВАРЯ", "ФЕВРАЛЯ", "МАРТА", "АПРЕЛЯ", "МАЯ", "ИЮНЯ", "ИЮЛЯ", "АВГУСТА", "СЕНТЯБРЯ", "ОКТЯБРЯ", "НОЯБРЯ", "ДЕКАБРЯ",
  ];
  //сделать enum обьектов

  const smallerDescription = description.substring(0, 300) + "...";
  const posterDate = moment(date).format("DD / MM / YYYY");
  const day = moment(date).format("DD");
  const year = moment(date).format("YYYY");
  const monthNumber = moment(date).format("M");
  const month = monthsArray[parseInt(monthNumber) - 1];

  let position = '';
  let newIndex = index;
  let currentSlide = currentPost;
  if (newIndex === currentSlide) {
    position = 'active'
  }
  if (newIndex > currentSlide) {
    position = 'bottom';
    if (newIndex === currentSlide + 1) {
      position = `${position} next`
    }
  }
  if (newIndex < currentSlide) {
    position = 'top';
    if (newIndex === currentSlide - 1) {
      position = `${position} previous`
    }
  }
  let isPostClicked = ``;
  if (touchedPost === index + 1 && isPostChosen) {
    isPostClicked = 'post-touched';
  } else if (touchedPost !== index + 1 && isPostChosen) {
    isPostClicked = 'opacity03';
  }
  let classList = `post ${position} ${isPostClicked}`;
  let backgroundPost = ``;
  if (isPostChosen) {
    if (touchedPost === id) {
      backgroundPost = `linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 0.01%,
                                                        rgba(15, 15, 16, 0.3) 20%, rgba(15, 15, 16, 0.4) 44%,
                                                         rgba(15, 15, 16, 0.85) 51.6%, rgba(15, 15, 16, 1) 80.34%)`;
    } else {
      backgroundPost = `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))`;
    }
  } else {
    backgroundPost = `linear-gradient(rgba(15, 15, 16, 0), rgba(15, 15, 16, 0.9))`;
  }
  let backgroundStyle = `${backgroundPost}, url(${imageUrl})`;

  const handleClick = () => {
    let touchedItem = id;
    if (touchedPost === touchedItem) {
      setIsPostChosen(!isPostChosen);
    } else {
      setIsPostChosen(true);
    }
    setTouchedPost(touchedItem);
  };

  return (
      <PosterItem classList={classList}
                  backgroundStyle={backgroundStyle}
                  handleClick={handleClick}
                  day={day}
                  month={month}
                  year={year}
                  title={title}
                  date={posterDate}
                  smallerDescription={smallerDescription}
                  url={url}
      />
  );
}

export default PosterItemContainer;