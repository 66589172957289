import React from 'react';
import ApplicationEnum from "../../../utils/enums/ApplicationEnum";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/reducers";

interface Props {
  active: Boolean,
}

const PhoneNumber = ({active}: Props) => {

  const {application} = useSelector((state: RootState) => state.page);

  return (
      <>
        {
          (application === ApplicationEnum.FABRIKA &&
              <a href="tel:+79606904880" className={`${active ? "" : "opacity06"}`}>+7 (960) 690 48 80</a>)
          ||
          (application === ApplicationEnum.BOTTLE &&
              <a href="tel:+79066926280" className={`${active ? "" : "opacity06"}`}>+7 (906) 692 62 80</a>)
        }
      </>
  );
};

export default PhoneNumber;