import {GroupWithProductsResDto} from "../../../api/dto/GroupWithProductsResDto";
import React from "react";
import MenuProductPage from "./MenuProductPage";

interface Props {
  parent: GroupWithProductsResDto | null
  groupWithSubgroups: GroupWithProductsResDto,
  active: boolean,
  parentGroup: string,
}

const MenuSubgroups: React.FC<Props> = ({
                                          groupWithSubgroups,
                                          parent,
                                          active,
                                          parentGroup
                                        }) => {

  return (
    <>
      {
        parent === null &&
        <div key={groupWithSubgroups.iikoId} className={"title"}>{active && parentGroup} /
          <span className={"title opacity06"}> {groupWithSubgroups.name.toUpperCase()}</span>
        </div>
      }

      {
        groupWithSubgroups?.groups?.length > 0 ?
          groupWithSubgroups.groups.map(group =>
            <MenuSubgroups groupWithSubgroups={group}
                           parent={groupWithSubgroups}
                           active={false}
                           parentGroup={group.name}/>
          )
          :
          <>
            {parent && <div className={"subtitle"}>{parentGroup}</div>}
            <MenuProductPage products={groupWithSubgroups.products}/>
          </>
      }
    </>
  );
}

export default MenuSubgroups;