import React, {useState} from 'react';
import {AdminAPI} from "../../api/api";
import {useDispatch} from "react-redux";
import {setPosters, signIn, signOut} from "../../actions/AdminAction";
import AuthForm from "../form/AuthForm";
import {AuthReqDto} from "../../api/dto/AuthReqDto";

const LoginPage = () => {

  const [loginError, setLoginError] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = ({username, password}: AuthReqDto) => {
    sessionStorage.setItem("username", username);
    sessionStorage.setItem("password", password);

    AdminAPI.getAllPosters()
        .then(res => {
          dispatch(setPosters((res.data.content)));
          dispatch(signIn());
        })
        .catch(err => {
          setLoginError(true);
          dispatch(signOut());
        });
  };

  return (
      <div className="admin">
        <div className="admin-header">
          <h3>Панель администратора</h3>
        </div>
        <h3>Введите данные для авторизации:</h3>
        <AuthForm handleSubmit={handleSubmit} isError={loginError}/>
      </div>
  );
}

export default LoginPage;