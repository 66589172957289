import {PaginationAction, PaginationActionType} from "../../actions/PaginationAction";

interface PaginationState {
  totalPages: number,
  currentPage: number
}

const initialState: PaginationState = {
  totalPages: 1,
  currentPage: 1
}

const paginationReducer = (state = initialState, action: PaginationAction) => {
  switch (action.type) {
    case PaginationActionType.SET_TOTAL_PAGES: {
      return {...state, totalPages: action.totalPages}
    }
    case PaginationActionType.SET_CURRENT_PAGE: {
      return {...state, currentPage: action.currentPage}
    }
    default: {
      return state;
    }
  }
}

export default paginationReducer;