import {Dispatch} from "redux";
import Api from "../api";
import {AxiosResponse} from "axios";
import MockResDto from "../api/dto/MockResDto";
import {RootState} from "../store/reducers";
import {PosterResDto} from "../api/dto/AuthReqDto";

export enum PosterActionType {
  SET_MOCK = "SET_MOCK",
  SET_ERROR = "SET_ERROR",
  SET_POSTERS = "SET_POSTERS",
  SET_LOADING = "SET_LOADING",
}

export interface PosterAction {
  type: PosterActionType,
  loading: boolean,
  isMock: boolean,
  error: Error,
  posters: Array<PosterResDto>
}

export const getPosters = (page: number = 1, size: number = 12) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    dispatch(setError(null));
    await dispatch(isMock());

    const {poster} = getState();
    if (poster.isMock || poster.error) {
      dispatch(setLoading(false));
      return;
    }

    try {
      const posters: Array<PosterResDto> = await Api.posterApi.getPosters(page, size);
      dispatch(setPosters(posters));
    } catch (error) {
      dispatch(setError(error));
    } finally {
      setLoading(false);
    }
  }
}

const setLoading = (loading: boolean) => ({type: PosterActionType.SET_LOADING, loading});
const setMock = (isMock: boolean) => ({type: PosterActionType.SET_MOCK, isMock});
const setError = (error: Error | null) => ({type: PosterActionType.SET_ERROR, error});
const setPosters = (posters: Array<PosterResDto>) => ({type: PosterActionType.SET_POSTERS, posters});

const isMock = (): any => async (dispatch: Dispatch) => {
  try {
    const dto: MockResDto = await Api.posterApi.isMock();
    dispatch(setMock(dto.isMock));
  } catch (error) {
    dispatch(setError(error));
  }
};
