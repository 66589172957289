import React, {CSSProperties} from 'react';
import Lottie from "react-lottie-player"
import preloader from '../../assests/preloader.json';

interface Props {
  style?: CSSProperties
}

const PreloaderPlayer: React.FC<Props> = ({style}) => {

  return (
    <Lottie animationData={preloader} style={style} speed={1} play loop/>
  );
};

export default PreloaderPlayer;