import React, {useState} from 'react';
import DaysEnum from "../../utils/enums/DaysEnum";
import WorkTime from "../common/WorkTime";

const Dates = () => {

  const currentDay = new Date().getDay();
  const [chosenDay, setChosenDay] = useState(currentDay);


  const handleClick = (newDay: DaysEnum) => {
    setChosenDay(newDay);
  };

  return (
      <div className="datetime">
        <div className="days">
          <div onClick={() => handleClick(DaysEnum.MONDAY)}
               className={`day ${currentDay === DaysEnum.MONDAY ? "day-current" : ""}
               ${chosenDay === DaysEnum.MONDAY ? "day-chosen" : ""}`}>
            ПН
          </div>
          <div onClick={() => handleClick(DaysEnum.TUESDAY)}
               className={`day ${currentDay === DaysEnum.TUESDAY ? "day-current" : ""}
                             ${chosenDay === DaysEnum.TUESDAY ? "day-chosen" : ""}`}>
            ВТ
          </div>
          <div onClick={() => handleClick(DaysEnum.WEDNESDAY)}
               className={`day ${currentDay === DaysEnum.WEDNESDAY ? "day-current" : ""}
                             ${chosenDay === DaysEnum.WEDNESDAY ? "day-chosen" : ""}`}>
            СР
          </div>
          <div onClick={() => handleClick(DaysEnum.THURSDAY)}
               className={`day ${currentDay === DaysEnum.THURSDAY ? "day-current" : ""}
              ${chosenDay === DaysEnum.THURSDAY ? "day-chosen" : ""}`}>
            ЧТ
          </div>
          <div onClick={() => handleClick(DaysEnum.FRIDAY)}
               className={`day ${currentDay === DaysEnum.FRIDAY ? "day-current" : ""}
              ${chosenDay === DaysEnum.FRIDAY ? "day-chosen" : ""}`}>
            ПТ
          </div>
          <div onClick={() => handleClick(DaysEnum.SATURDAY)}
               className={`day ${currentDay === DaysEnum.SATURDAY ? "day-current" : ""}
               ${chosenDay === DaysEnum.SATURDAY ? "day-chosen" : ""}`}>
            СБ
          </div>
          <div onClick={() => handleClick(DaysEnum.SUNDAY)}
               className={`day ${currentDay === DaysEnum.SUNDAY ? "day-current" : ""}
              ${chosenDay === DaysEnum.SUNDAY ? "day-chosen" : ""}`}>
            ВС
          </div>
        </div>
        <div>
          <WorkTime day={chosenDay}
                    currentDay={currentDay}
          />
        </div>
      </div>
  );
}

export default Dates;