import React, {useState} from 'react';
import {ReactDOM} from 'react';
import {Form, Formik, Field, FormikProps} from "formik";
import "../../assests/styles/admin.scss";
import ImgInput from "./ImgInput";
import moment from "moment";
import {AdminAPI} from "../../api/api";

interface FormValues {
  title: string,
  description: string,
  date: string,
  posterLink: string,
  img: string,
  imageName?: string,
}

interface AdminsChangesProps {
  closeModal: () => void,
  fetchPosters: () => void,
}

//FIXME im duplicate
const CreatePoster = ({closeModal, fetchPosters}: AdminsChangesProps) => {

  const [newImageName, setNewImageName] = useState("");
  const [newImgFile, setNewImgFile] = useState<string | Blob>("");

  const handleSubmit = (values: FormValues) => {

    const dateParts: any = values.date.split(".");
    const date: any = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    const newData = moment(date);

    const posterData = {
      date: newData,
      title: values.title,
      description: values.description,
      posterLink: values.posterLink,
    }
    const json = JSON.stringify(posterData);
    const blob = new Blob([json], {
      type: 'application/json'
    });

    const formData = new FormData();
    if (newImgFile) {
      formData.append("file", newImgFile, newImageName);
    }
    formData.append("poster", blob);

    AdminAPI.createPoster(formData)
        .then(res => fetchPosters())
        .catch(err => console.log(err));
  }

  return (
      <Formik
          initialValues={{
            title: "",
            description: "",
            date: "",
            posterLink: "",
            img: "",
            image: "",
          }}
          onSubmit={handleSubmit}
      >
        {(formik: FormikProps<FormValues>) => {
          const isDataValid = moment(formik.values.date, 'DD.MM.YYYY', true).isValid();
          const length = {
            title: formik.values.title.length,
            description: formik.values.description.length,
            date: formik.values.date.length
          }
          const error = {title: length.title > 30, description: length.description > 200, date: length.date < 1};
          const disableSubmit = formik.isSubmitting || error.title || error.description || error.date || !isDataValid;

          return (
              <Form onSubmit={formik.handleSubmit}>
                <div className="modal modal-add">
                  <div className="admin modal-nested">
                    <div className="line-after">
                      <h3>Новая афиша <span/></h3>
                    </div>
                    <div className={`textInput ${length.title > 30 ? "err" : ""}`}>
                      <label htmlFor="title">{length.title}/30</label>
                      <Field name="title" placeholder="Название мероприятия (без точки в конце)"/></div>
                    <div className={`textInput ${length.description > 200 ? "err" : ""}`}>
                      <label htmlFor="description">{length.description}/200</label>
                      <Field name="description" placeholder="Описание мероприятия"/>
                    </div>
                    <div className={`textInput ${length.date !== 0 && !isDataValid ? "err" : ""}`}>
                      <Field name="date" placeholder="Дата мероприятия - строго “дд.мм.гггг”"/>
                    </div>
                    <div className="textInput">
                      <Field name="posterLink" placeholder="Ссылка на регистрацию - https://primer.ru"/>
                    </div>
                    <ImgInput setNewImgFile={setNewImgFile}
                              newImageName={newImageName}
                              setNewImageName={setNewImageName}
                    />
                    <div className="line-after">
                      <div className="buttons flex">
                        <button type="submit" disabled={disableSubmit} className={`${disableSubmit ? "disable" : ""}`}>
                          Добавить
                        </button>
                        <button onClick={closeModal}>
                          Отмена
                        </button>
                      </div>
                      <h3/>
                    </div>
                  </div>
                </div>
              </Form>
          )
        }}
      </Formik>
  );
}

export default CreatePoster;
