import React from 'react';
import noPhoto from "../../../assests/images/product-empty-image.svg"
import {useDispatch, useSelector} from "react-redux";
import {
  basketAddProductItem,
  basketPopProductFabrika, basketPushProductBottle,
  basketPushProductFabrika,
  basketRemoveProduct, basketRemoveProductBottle, basketRemoveProductFabrika
} from "../../../actions/BasketAction";
import ApplicationEnum from "../../../utils/enums/ApplicationEnum";
import {RootState} from "../../../store/reducers";
import {ProductResDto} from "../../../api/dto/ProductResDto";
import {prettyDOM} from "@testing-library/react";

interface Props {
  product: ProductResDto,
  amount: number
}

const BasketItem = ({product,amount}: Props) => {

  const dispatch = useDispatch();
  const {application} = useSelector((state: RootState) => state.page);

  const onHandleMinus = () => {
    if (amount> 1) {
      if(application===ApplicationEnum.FABRIKA) dispatch(basketPopProductFabrika(product));
      else if(application===ApplicationEnum.BOTTLE) dispatch(basketPushProductFabrika(product))
    }
  };

  const onHandlePlus = () => {
    if(application===ApplicationEnum.FABRIKA) dispatch(basketPushProductFabrika(product));
    else if(application===ApplicationEnum.BOTTLE) dispatch(basketPushProductBottle(product))
  };

  const deleteProduct = () => {
    if(application===ApplicationEnum.FABRIKA) dispatch(basketRemoveProductFabrika(product));
    else if(application===ApplicationEnum.BOTTLE) dispatch(basketRemoveProductBottle(product))
  }

  return (
      <div className="basket-form__list-item">
        <img src={noPhoto} alt="Продукт"/>

        <div className="basket-form__food">
          <div className="basket-form__descr">
            <div className="basket-form__descr-title">{product.name}</div>
            <div className="basket-form__descr-price">{product.measureUnit} / {product.price} &#x20bd;</div>
          </div>

          <div className="basket-form__info">
            <div className="calculate">
              <div>
                <button className="minus" onClick={onHandleMinus}>
                  -
                </button>
                <div className="value">{amount}</div>
                <button className="plus" onClick={onHandlePlus}>
                  +
                </button>
              </div>
            </div>

            <div className="basket-form__total-price">{amount * +product.price} ₽</div>
            <button className="basket-form__delete-item pointer" onClick={deleteProduct}>&#10005;</button>
          </div>
        </div>
      </div>
  );
}

export default BasketItem;