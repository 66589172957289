import {ProductDto} from "../api/dto/GetProductDto";
import ApplicationEnum from "../utils/enums/ApplicationEnum";
import {ProductResDto} from "../api/dto/ProductResDto";

export enum BasketActionType {
  TOGGLE_ACTIVE = "TOGGLE_ACTIVE",
  ADD_PRODUCT = "ADD_PRODUCT",
  PUSH_PRODUCT_FABRIKA = "PUSH_PRODUCT_FABRIKA",
  POP_PRODUCT_FABRIKA = "POP_PRODUCT_FABRIKA",
  REMOVE_PRODUCT_FABRIKA = "REMOVE_PRODUCT_FABRIKA",
  PUSH_PRODUCT_BOTTLE = "PUSH_PRODUCT_BOTTLE",
  POP_PRODUCT_BOTTLE = "POP_PRODUCT_BOTTLE",
  REMOVE_PRODUCT_BOTTLE = "REMOVE_PRODUCT_BOTTLE",
  REMOVE_PRODUCT = "REMOVE_PRODUCT",
  ADD_PRODUCT_ITEM = "ADD_PRODUCT_ITEM",
  REMOVE_PRODUCT_ITEM = "REMOVE_PRODUCT_ITEM",
  CLEAR = "CLEAR"
}

export interface BasketAction {
  type: BasketActionType,
  appType: ApplicationEnum
  product: ProductDto,
  product2: ProductResDto,
  isActive: boolean
}


export interface CountedProduct {
  product: ProductResDto,
  amount: number
}

export const basketToggleActive = (isActive: boolean) => ({type: BasketActionType.TOGGLE_ACTIVE, isActive});
export const basketAddProduct = (product: ProductDto,appType: ApplicationEnum) => ({type: BasketActionType.ADD_PRODUCT, product,appType});
export const basketRemoveProduct = (product: ProductDto,appType: ApplicationEnum) => ({type: BasketActionType.REMOVE_PRODUCT, product,appType});
export const basketAddProductItem = (product: ProductDto,appType: ApplicationEnum) => ({type: BasketActionType.ADD_PRODUCT_ITEM, product,appType});
export const basketRemoveProductItem = (product: ProductDto,appType: ApplicationEnum) => ({type: BasketActionType.REMOVE_PRODUCT_ITEM, product,appType});
export const basketClear = (appType: ApplicationEnum) => ({type: BasketActionType.CLEAR,appType});
export const basketPushProductFabrika = (product2: ProductResDto) => ({type: BasketActionType.PUSH_PRODUCT_FABRIKA, product2});
export const basketPopProductFabrika = (product2: ProductResDto) => ({type: BasketActionType.POP_PRODUCT_FABRIKA, product2});
export const basketPushProductBottle = (product2: ProductResDto) => ({type: BasketActionType.PUSH_PRODUCT_BOTTLE, product2});
export const basketPopProductBottle = (product2: ProductResDto) => ({type: BasketActionType.POP_PRODUCT_BOTTLE, product2});
export const basketRemoveProductFabrika = (product2: ProductResDto) => ({type: BasketActionType.REMOVE_PRODUCT_FABRIKA, product2});
export const basketRemoveProductBottle = (product2: ProductResDto) => ({type: BasketActionType.REMOVE_PRODUCT_BOTTLE, product2});
