import React, {useEffect} from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import Order from "./pages/Order"
import OrderConditions from './pages/OrderConditions'
import Landing from './pages/Landing';
import AdminContainer from "./components/admin/AdminContainer";
import Menu from "./pages/Menu";
import WindowUtils from "./utils/WindowUtils";

const App = () => {
    const {pathname} = useLocation();

    useEffect(() => WindowUtils.scrollToTop(),[pathname]);

    return (
        <Switch>
            <Route path={"/"} exact render={() => <Landing/>}/>
            <Route path={"/admin"} exact render={() => <AdminContainer/>}/>
            <Route path={"/menu"} exact render={() => <Menu/>}/>
            <Route path={"/order"} exact render={() => <Order/>}/>
            <Route path={"/order/conditions"} exact render={() => <OrderConditions/>}/>
            <Redirect to={"/"}/>
        </Switch>
    );
};

export default App;
