import React, {SetStateAction} from 'react';
import {NavLink} from "react-router-dom";
import arrows from "../../assests/images/arrows.svg";
import logoB from "../../assests/images/logo.svg";
import logoF from "../../assests/images/header_logo.svg";
import Dates from "../header/Dates";
import PhoneNumber from "../common/contacts/PhoneNumber";
import ApplicationEnum from "../../utils/enums/ApplicationEnum";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import {switchAppToBottle, switchAppToFabrika} from "../../actions/PageAction";
import {Dispatch} from "react";

interface LandingProps {
  isPromoAnimation: boolean,
  setIsPromoAnimation: Dispatch<SetStateAction<boolean>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

const HeaderLanding = ({isPromoAnimation, setIsPromoAnimation, setIsLoading}: LandingProps) => {

  const {application} = useSelector((state: RootState) => state.page);
  const dispatch = useDispatch();

  const handleClick = () => {
    setIsPromoAnimation(false);
    if (application === ApplicationEnum.FABRIKA) {
      dispatch(switchAppToBottle());
    } else if (application === ApplicationEnum.BOTTLE) {
      dispatch(switchAppToFabrika());
    }
    setIsLoading(true);
  };

  const handleMouseOn = () => {
    setIsPromoAnimation(true);
  };

  const handleMouseOff = () => {
    setIsPromoAnimation(false);
  };

  return (
    <header className="header_body header_body-landing">
      <div className="flex row">
        <div className="info">
          <div className="phone">
            <span className="opacity06">Звоните:&nbsp;&nbsp;</span>
            <PhoneNumber active={true}/>
          </div>
        </div>
        <div className={`link `
          + `${isPromoAnimation ? "link-animation" : ""} `
          + `${application === ApplicationEnum.FABRIKA ? "link-bottle" : "link-fabrika"}`}
             onMouseEnter={handleMouseOn} onMouseLeave={handleMouseOff} onClick={handleClick}
        >
          <img src={application === ApplicationEnum.FABRIKA ? logoB : logoF} alt="image of logo"
               className={` imgLogo ${application === ApplicationEnum.FABRIKA ? " logoFabrika" : "logoBottle"}`}/>
          <NavLink to="/">
            <span>{application === ApplicationEnum.FABRIKA ? "В Бутылочную" : "В Фабрику"}</span>
            <img src={arrows} alt="arrows"/>
          </NavLink>
        </div>
        <div className="info">
          <Dates/>
        </div>
      </div>
    </header>
  );
}

export default HeaderLanding;