import {Body} from "../store/reducers/modalKitchenReducer";

export interface ModalKitchenAction {
    type: ModalKitchenActionType,
    topGroupNameKitchen: Body,
    topGroupNameBar: Body
}

export enum ModalKitchenActionType {
    SET_TOP_GROUP_NAME_KITCHEN = "SET_TOP_GROUP_NAME_KITCHEN",
    SET_TOP_GROUP_NAME_BAR = "SET_TOP_GROUP_NAME_BAR"
}

export const onSetTopGroupNameKitchen = (topGroupNameKitchen: Body) => ({
    type: ModalKitchenActionType.SET_TOP_GROUP_NAME_KITCHEN,
    topGroupNameKitchen: topGroupNameKitchen
});
export const onSetTopGroupNameBar = (topGroupNameBar: Body) => ({
    type: ModalKitchenActionType.SET_TOP_GROUP_NAME_BAR,
    topGroupNameBar: topGroupNameBar
});