import React, {useEffect} from 'react';
import Admin from "./Admin";
import CreatePoster from "./CreatePoster";
import DeletePoster from "./DeletePost";
import {AdminAPI} from "../../api/api";
import Portal from "../portal/Portal";
import {useDispatch, useSelector} from "react-redux";
import {AdminModalEnum} from "../../utils/enums/AdminModalEnum";
import AdminLoginPage from "./LoginPage";
import {RootState} from "../../store/reducers";
import {closeModals, setPosters, signOut} from "../../actions/AdminAction";

const AdminContainer = () => {

  const {currentModal, posts, currentId, isAuth} = useSelector((state: RootState) => state.adminPage);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchPosters();
  }, []);

  const fetchPosters = () => {
    AdminAPI.getAllPosters()
        .then(res => {
          dispatch(setPosters((res.data.content)));
          handleCloseModals();
        })
        .catch(err => {
          console.error("Backend error", err);
          dispatch(signOut());
        });
  }

  const handleCloseModals = () => dispatch(closeModals());

  if (!isAuth) {
    return <AdminLoginPage/>
  }

  return (
      <>
        <Admin posts={posts} fetchPosters={fetchPosters}/>
        {
          currentModal === AdminModalEnum.CREATE &&
          <Portal>
            <CreatePoster fetchPosters={fetchPosters} closeModal={handleCloseModals}/>
          </Portal>
        }
        {
          currentModal === AdminModalEnum.DELETE &&
          <Portal>
            <DeletePoster fetchPosters={fetchPosters} currentId={currentId} closeModal={handleCloseModals}/>
          </Portal>
        }
      </>
  );
};

export default AdminContainer;