import AxiosUtils from "./helpers/AxiosUtils";
import {CreateOrderDto} from "./dto/CreateOrderDto";

const {REACT_APP_BACKEND_HOST} = process.env;
const baseURL: string = REACT_APP_BACKEND_HOST || "http://localhost:8888/api/v1";


export const AdminAPI = {
  getAllPosters() {
    return AxiosUtils.getAdminInstance().get(`/poster` + AxiosUtils.addPagination(1, 50));
  },

  createPoster(data: any) {
    return AxiosUtils.getAdminInstance().post(`/poster`, data);
  },

  deletePoster(id: number | null) {
    return AxiosUtils.getAdminInstance().delete(`/poster/${id}`);
  },

  updatePoster(id: number | null, data: any) {
    return AxiosUtils.getAdminInstance().put(`/poster/${id}`, data, {
      headers: {
        'Content-Type': `multipart/form-data`,
      }
    });
  },

  swipeUp(id: number | null) {
    return AxiosUtils.getAdminInstance().patch(`/poster/${id}`, {"swipe": "UP"});
  },

  swipeDown(id: number | null) {
    return AxiosUtils.getAdminInstance().patch(`/poster/${id}`, {"swipe": "DOWN"});
  },
  isMock() {
    return AxiosUtils.getAdminInstance().get('/poster/mock');
  },
  toggleIsMock(data: any) {
    return AxiosUtils.getAdminInstance().put('/poster/mock', data);
  },

};

export const BaseAPI = {
  async getPosters(quantityRows: number, size: number = 12) {
    return AxiosUtils.getBaseInstance().get(`/poster` + AxiosUtils.addPagination(quantityRows, size));
  },

  async isMock() {
    return AxiosUtils.getBaseInstance().get('/poster/mock');
  },

  getImageUrl(filename: string) {
    if (!filename) {
      throw Error("filename is null");
    }
    return `${baseURL}/image/${filename}`;
  },

  async getGroupsByTopGroupIikoId(iikoId: string,needProducts: boolean) {
    if (!iikoId) {
      throw Error("topGroup is null");
    }
    const response = await AxiosUtils.getBaseInstance().get(`/iiko/nomenclature/group/all/${iikoId}?needProducts=${needProducts}`);
    return response.data;
  },

  async getProductsByTopGroupIikoId(iikoId: string, page: number, size: number = 15) {
    if (!iikoId) {
      throw Error("topGroup is null");
    }
    const response = await AxiosUtils.getBaseInstance().get(`/iiko/nomenclature/product/all/${iikoId}`
        + AxiosUtils.addPagination((page - 1), size));
    return response.data;
  },

  async createOrder(order: CreateOrderDto) {
    return AxiosUtils.getBaseInstance().post("/iiko/order", order);
  }
};