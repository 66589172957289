import {CreateOrderDto} from "../../api/dto/CreateOrderDto";
import {ItemDto} from "../../api/dto/ItemDto";
import {OrderAction, OrderActionType} from "../../actions/OrderAction";
import ApplicationEnum from "../../utils/enums/ApplicationEnum";


interface OrderState {
  order: CreateOrderDto
}

const initialState: OrderState = {
  order: {
    order: {
      comment: "",
      isSelfService: false,
      items: new Array<ItemDto>(),
      paymentEnum: "CARD",
      applicationEnum: ApplicationEnum.FABRIKA
    },
    customer: {
      name: "",
      surname: "",
      phone: ""
    }
  }
}

const orderReducer = (state = initialState, action: OrderAction) => {
  switch (action.type) {
    case OrderActionType.SET_ORDER: {
      return {...state, order: action.order}
    }
    default: {
      return state
    }
  }
}

export default orderReducer;