import ApplicationEnum from "../../utils/enums/ApplicationEnum";
import PageEnum from "../../utils/enums/PageEnum";
import {PageAction, PageActionType} from "../../actions/PageAction";

interface PageState {
  application: ApplicationEnum,
  page: PageEnum
}

const initialState: PageState = {
  application: ApplicationEnum.FABRIKA,
  page: PageEnum.NONE
}

const pageReducer = (state: PageState = initialState, action: PageAction) => {
  switch (action.type) {
    case PageActionType.SWITCH_APP_TO_BOTTLE:
      return {...state, application: ApplicationEnum.BOTTLE};
    case PageActionType.SWITCH_APP_TO_FABRIKA:
      return {...state, application: ApplicationEnum.FABRIKA};
    case PageActionType.SWITCH_PAGE:
      return {...state, page: action.page};
    default:
      return state;
  }
}

export default pageReducer;