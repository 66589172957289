import React from 'react';
import ApplicationEnum from "../../../utils/enums/ApplicationEnum";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/reducers";

const InstagramLink = () => {

  const {application} = useSelector((state: RootState) => state.page);

  return (
      <>
        {
            (application === ApplicationEnum.FABRIKA &&
                <a href="https://www.instagram.com/silver_fabrika_bar/?hl=ru" target="_blank"/>)
            ||
            (application === ApplicationEnum.BOTTLE &&
                <a href="https://www.instagram.com/butylo4naya?igshid=10nkicxdojinq" target="_blank"/>)
        }
      </>
  );
};

export default InstagramLink;