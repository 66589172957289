import React from 'react';
import {Field, Form, Formik} from "formik";
import {AuthReqDto} from "../../api/dto/AuthReqDto";

interface AuthFormProps {
  handleSubmit: ({username, password}: AuthReqDto) => void,
  isError: boolean
}

const AuthForm = ({handleSubmit, isError} : AuthFormProps) => {

  return (
      <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          onSubmit={handleSubmit}
      >
        {formik => (
            <Form onSubmit={formik.handleSubmit}>
              <div className="textInput">
                <Field name="username" placeholder="Логин" autoComplete="off"/>
              </div>
              <div className="textInput">
                <Field name="password" type="password" placeholder="Пароль"/>
              </div>
              <div className="flex">
                <button type="submit">Войти</button>
                {
                  isError && <div className="err-login">Ошибка: неверный логин или пароль</div>
                }
              </div>
            </Form>
        )}
      </Formik>
  );
};

export default AuthForm;