import React, {useEffect, useState} from 'react';
import mockProductImage from "../../../assests/images/product-empty-image.svg";
import {ProductResDto} from "../../../api/dto/ProductResDto";
import Counter from "../../common/Counter";
import {
  basketPopProductFabrika,
  basketPushProductBottle,
  basketPushProductFabrika,
  CountedProduct
} from "../../../actions/BasketAction";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/reducers";
import ApplicationEnum from "../../../utils/enums/ApplicationEnum";

interface Props {
  product: ProductResDto
}

const MenuProductCard: React.FC<Props> = ({product}) => {

  const [countedProduct, setCountedProduct] = useState<CountedProduct>();
  const {application} = useSelector((state: RootState) => state.page);
  const {products: fabrika} = useSelector((state: RootState) => state.basket.basketFabrika);
  const {products: bottle} = useSelector((state: RootState) => state.basket.basketBottle);
  const dispatch = useDispatch();

  useEffect(() => {
    if (application === ApplicationEnum.FABRIKA) {
      setCountedProduct(fabrika.find(p => p.product.iikoId === product.iikoId));
    } else if (application === ApplicationEnum.BOTTLE) {
      setCountedProduct(bottle.find(p => p.product.iikoId === product.iikoId));
    }
  }, [fabrika, bottle]);

  const handleOrder = () => {
    if (application === ApplicationEnum.FABRIKA) {
      dispatch(basketPushProductFabrika(product));
    } else if (application === ApplicationEnum.BOTTLE) {
      dispatch(basketPushProductBottle(product));
    }
  }

  const handleMinus = () => {
    dispatch(basketPopProductFabrika(product));
  }

  const handlePlus = () => {
    dispatch(basketPushProductFabrika(product));
  }

  return (
    <div className={"product"}>
      <img src={product.images[0] ? product.images[0] : mockProductImage} alt={"Продукт"}/>
      <div className={"name"}>{product.name}</div>
      <div className={"price"}>{product.weight} {product.measureUnit} / {product.price} ₽</div>
      {
        countedProduct ?
          <div className={"order-active"}>
            <div className={"opacity06"}>ДОБАВЛЕНО</div>
            <Counter value={countedProduct.amount} handleMinus={handleMinus} handlePlus={handlePlus}/>
          </div>
          :
          <div className={"order-inactive"} onClick={handleOrder}>ЗАКАЗАТЬ</div>
      }
    </div>
  );
}

export default MenuProductCard;