import React from 'react';
import {AdminAPI} from "../../api/api";

interface DeletePostProps {
  closeModal: () => void,
  currentId: number | null,
  fetchPosters: () => void,
}

const DeletePost = ({closeModal, currentId, fetchPosters}: DeletePostProps) => {

  const handleDelete = () => {
    AdminAPI.deletePoster(currentId)
        .then(res => fetchPosters())
        .catch(err => console.error(err));
  }

  return (
      <div className="modal modal-delete">
        <div className="window modal-nested">
          <h3>Вы действительно хотите удалить эту афишу?</h3>
          <div className="flex">
            <button className="yes" onClick={handleDelete}>
              Да
            </button>
            <button onClick={() => closeModal()}>
              Нет
            </button>
          </div>
        </div>
      </div>
  );
}

export default DeletePost;