import {ProductDto} from '../../api/dto/GetProductDto';
import {GetGroupDto} from "../../api/dto/GetGroupDto";
import {ProductAction, ProductActionType} from "../../actions/ProductsAction";

export interface Body {
    kitchen: Array<ProductDto>,
    bar: Array<GetGroupDto>
}

export const bodyInitialState: Body = {
    kitchen: [],
    bar: []
}

interface ProductState {
    kitchen: Body,
    bar: Body
}

const initialState: ProductState = {
    kitchen: bodyInitialState,
    bar: bodyInitialState
}

const productReducer = (state = initialState, action: ProductAction) => {
    switch (action.type) {
        case ProductActionType.SET_KITCHEN: {
            return {...state, kitchen: action.kitchen}
        }
        case ProductActionType.SET_BAR: {
            return {...state, bar: action.bar}
        }
        default: {
            return state;
        }
    }
}

export default productReducer;
