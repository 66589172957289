import React from 'react';

const SelfTrack = () => {
    return (
        <div className="self-track">
            <p className="selft-track__text opacity06 ">Забрать самостоятельно по адресу: </p>
            <address className="self-track__address">Курск, Ленина 77Б</address>
        </div>
    )
}

export default SelfTrack;