import React from "react";

const MenuNotFound: React.FC = () => {

  return (
    <div className="not-found">
      <div>НИЧЕГО НЕ НАЙДЕНО</div>
      <p>Мы уже работаем над решением этой проблемы,</p>
      <p>приносим извинения за неудобства:(</p>
    </div>
  );
}

export default MenuNotFound;