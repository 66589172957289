import React from 'react';
import ApplicationEnum from "../../../utils/enums/ApplicationEnum";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/reducers";

const VkontakteLink = () => {

  const {application} = useSelector((state: RootState) => state.page);

  return (
      <>
        {
          (application === ApplicationEnum.FABRIKA &&
              <a className="vkontakte" href="https://vk.com/silverfbar" target="_blank"/>)
          ||
          (application === ApplicationEnum.BOTTLE &&
              <a className="vkontakte" href="https://vk.com/butylochnaya" target="_blank"/>)
        }
      </>
  );
};

export default VkontakteLink;