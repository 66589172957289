import React, {useState} from 'react';
import basketActive from '../../../assests/images/basket.svg';
import basketClosed from '../../../assests/images/basket_closed.svg';
import BasketForm from "./BasketForm";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/reducers";
import ApplicationEnum from "../../../utils/enums/ApplicationEnum";
import {basketToggleActive} from "../../../actions/BasketAction";

const Basket: React.FC = () => {

    const basket = useSelector((state: RootState) => state.basket);
    const {application} = useSelector((state: RootState) => state.page);
    const dispatch = useDispatch();

    const onClickBasket = () => {
        dispatch(basketToggleActive(!basket.isActive));
    }

    return (
        <div className="basket pointer">
            <div className="dishesPrice">
                <div>{application === ApplicationEnum.FABRIKA ? basket.basketFabrika.price : basket.basketBottle.price} ₽</div>
                <hr/>
                <div>{application === ApplicationEnum.FABRIKA ? basket.basketFabrika.dishes : basket.basketBottle.dishes} блюд</div>
            </div>
            <div className="img" onClick={onClickBasket}>
                <img src={basket.isActive ? basketClosed : basketActive} alt={"Корзина"}/>
            </div>
            <div
                className="dishesPriceM">{application === ApplicationEnum.FABRIKA ? basket.basketFabrika.price : basket.basketBottle.price} ₽
            </div>
            <BasketForm/>
        </div>
    );
}

export default Basket;