import PageEnum from "../utils/enums/PageEnum";

export enum PageActionType {
  SWITCH_APP_TO_BOTTLE = "SWITCH_APP_TO_BOTTLE",
  SWITCH_APP_TO_FABRIKA = "SWITCH_APP_TO_FABRIKA",
  SWITCH_PAGE ="SWITCH_PAGE"
}

export interface PageAction {
  type: PageActionType,
  page: PageEnum
}

export const switchAppToBottle = () => ({type: PageActionType.SWITCH_APP_TO_BOTTLE});
export const switchAppToFabrika = () => ({type: PageActionType.SWITCH_APP_TO_FABRIKA});
export const switchPage = (page: PageEnum) => ({type: PageActionType.SWITCH_PAGE, page});

