import React from 'react';
import DaysEnum from "../../utils/enums/DaysEnum";
import {useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import ApplicationEnum from "../../utils/enums/ApplicationEnum";

interface Props {
  day: DaysEnum;
  currentDay: number;
}

const WorkTime = ({day, currentDay}: Props) => {

  const {application} = useSelector((state: RootState) => state.page);

  const dayTimeClassName = () => {
    if (day === DaysEnum.MONDAY) {
      return ("TimeMargin-monday");
    } else if (day === DaysEnum.TUESDAY) {
      return ("TimeMargin-tuesday");
    } else if (day === DaysEnum.WEDNESDAY) {
      return ("TimeMargin-wednesday");
    } else if (day === DaysEnum.THURSDAY) {
      return ("TimeMargin-thursday");
    } else if (day === DaysEnum.FRIDAY) {
      return ("TimeMargin-friday");
    } else if (day === DaysEnum.SATURDAY) {
      return ("TimeMargin-saturday");
    } else if (day === DaysEnum.SUNDAY) {
      return ("TimeMargin-sunday");
    }
  }

  return (
      <div className={`TimeMargin ${dayTimeClassName()}`}>
        {
            application === ApplicationEnum.BOTTLE &&
            <>12:00 - 01:00</>
        }
        {
            application === ApplicationEnum.FABRIKA && (
                (day === DaysEnum.FRIDAY || day === DaysEnum.SATURDAY) ?
                    <>15.00 - 06.00</>
                    :
                    <>15.00 - 03.00</>
            )
        }
      </div>
  );
}

export default WorkTime;