import React from "react";

interface PromoButtonProps {
  text: string,
}

const PromoButton = ({text}: PromoButtonProps) => {

    return (
         <button className="promoButton"> {text} </button>
    );
};

export default PromoButton;