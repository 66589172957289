import React from "react";

const CovidPoster = () => {

  return (
      <div className="flex covidPoster">
        <div className="covidPoster-info">
        <h1>Здесь должны были быть афиши...</h1>
        <p>Но пока что их нет. Потому что карантин :(</p>
        <p>Мы надеемся, что он скоро закончится и мы снова сможем радовать вас концертами.</p>
        </div>
      </div>
  );
}

export default CovidPoster;