import React, {useEffect, useState} from 'react';
import {BaseAPI} from "../../../../api/api";
import posterRowBg from "../../../../assests/images/posterBg.png";
import Preloader from "../../../common/Preloader";
import CovidPoster from "./CovidPoster";
import Poster from "./Poster";
import PosterItemContainer from "./PosterItemContainer";

interface PostersBody {
  date: string,
  description: string,
  id: number,
  imageName: string,
  title: string,
  posterLink: string,
}

const PosterContainer = () => {

  const [currentPost, setCurrentPost] = useState(0);
  const [touchedPost, setTouchedPost] = useState(0);
  const [quantityRows, setQuantityRows] = useState(1);
  const [posters, setPosters] = useState<PostersBody[]>([]);
  const [hasPosters, setHasPosters] = useState(true);
  const [isRightToggleClicked, setIsRightToggleClicked] = useState(false);
  const [isLeftToggleClicked, setIsLeftToggleClicked] = useState(false);
  const [isPostChosen, setIsPostChosen] = useState(false);
  const [backError, setBackError] = useState(false);
  const [isFirstFetch, setIsFirstFetch] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    async function addPosters() {
      setIsFetching(true);

      const isMockRes = await BaseAPI.isMock();
      if (isMockRes.data.isMock) {
        setHasPosters(false);
        return;
      }
      const res = await BaseAPI.getPosters(quantityRows);
      if (res.data.totalElements === 0) {
        setHasPosters(false);
        return;
      }

      const newPosters = [...res.data.content];
      newPosters.forEach((poster: PostersBody) => {
        poster.imageName = BaseAPI.getImageUrl(poster.imageName);
      });
      setPosters(prev => [...prev, ...newPosters]);
    }

    addPosters()
      .then(() => {
        setBackError(false);
        setIsFetching(false);
        setIsFirstFetch(false);
      })
      .catch((e: Error) => {
        setBackError(true);
        setIsFetching(false);
        setIsFirstFetch(false);
        console.error(e);
      });
  }, [quantityRows, setQuantityRows]);

  const handleLeft = () => {
    setIsLeftToggleClicked(true);
    setTimeout(() => setIsLeftToggleClicked(false), 300);
    setCurrentPostAndQuantityRows(currentPost - 1);
  };

  const handleRight = () => {
    setIsRightToggleClicked(true);
    setTimeout(() => setIsRightToggleClicked(false), 300);
    setCurrentPostAndQuantityRows(currentPost + 1);
  }

  const setCurrentPostAndQuantityRows = (nextPost: number) => {
    setCurrentPost(nextPost);
    if (nextPost % 7 === 0) {
      setQuantityRows(quantityRows + 1);
    }
  }

  let posterGradient = ``;
  if (!isPostChosen) {
    posterGradient = `linear-gradient(rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.5) 3%,
                                      rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.95) 100%), url(${posterRowBg}`;
  } else if (isPostChosen && touchedPost !== 0) {
    posterGradient = `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.95)),url(${posters[touchedPost - 1].imageName})`;
  }
  const posterStyle = {backgroundImage: posterGradient};

  if (isFetching && isFirstFetch) {
    return <Preloader medium={false} small={false}/>
  } else if (backError || !hasPosters) {
    return <CovidPoster/>;
  }

  return (
    <Poster posterStyle={posterStyle}
            currentPost={currentPost}
            isLeftToggleClicked={isLeftToggleClicked}
            isRightToggleClicked={isRightToggleClicked}
            postersLength={posters.length}
            handleLeft={handleLeft}
            handleRight={handleRight}
    >
      {
        posters.map((item, index) => <PosterItemContainer
            key={index}
            setTouchedPost={setTouchedPost}
            setIsPostChosen={setIsPostChosen}
            currentPost={currentPost}
            touchedPost={touchedPost}
            isPostChosen={isPostChosen}
            description={item.description}
            title={item.title}
            index={index}
            imageUrl={item.imageName}
            url={item.posterLink}
            id={index + 1}
            date={item.date}
          />
        )
      }
    </Poster>
  );
};

export default PosterContainer;