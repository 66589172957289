import {applyMiddleware, createStore} from "redux";
import rootReducer from "./reducers";
import {loadState, saveState} from "../service/localStorage";
import thunk from 'redux-thunk';

const persistedState = loadState();

const store = createStore(rootReducer, persistedState, applyMiddleware(thunk));

store.subscribe(() => saveState(store.getState()));

export default store;