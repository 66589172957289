import React from 'react';
import PromoButton from "../../common/PromoButton";
import promoF from "../../../assests/images/promoFooter.png";
import {NavLink} from "react-router-dom";

const FooterFabrikaBlock = () => {

  return (
      <div className="promo__footerF">
        <div>
          <h1>МЕНЮ</h1>
          <p>
            Мы приглашаем Вас в гастрономическое приключение по уникальным сочетаниям авторской кухни grill-бара
            «Серебряная FABRIKA». Фирменные блюда от нашего шеф-повара, мясо и рыба, приготовленные на углях
            Аргентинского гриля Parilla, копчености из Техасского Смокера. Только лучшее качество ингредиентов и самые
            незабываемые вкусовые впечатления.
          </p>
          <span style={{height: "15px"}}/>
          <NavLink to={"/menu"}><PromoButton text={"ПЕРЕЙТИ В МЕНЮ"}/></NavLink>
        </div>
        <img src={promoF} alt={"foods promo"}/>
      </div>
  );
}

export default FooterFabrikaBlock;