import React, {useEffect, useState} from 'react';
import arrows from "../../assests/images/ArrowAdmin.svg";
import AdminInfo from "./AdminInfo";
import {AdminAPI} from "../../api/api";
import {useDispatch} from "react-redux";
import {openCreateModal, signOut} from "../../actions/AdminAction";
import {AdminContentEnum} from "../../utils/enums/AdminContentEnum";

interface AdminHeaderProps {
  postersNumber: number | null,
  content: AdminContentEnum,
  setContent: (param: AdminContentEnum) => void,
}

const AdminHeader = ({postersNumber, setContent, content}: AdminHeaderProps) => {

  const [isPosterBlocked, setIsPostersBlocked] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    AdminAPI.isMock()
        .then(res => setIsPostersBlocked(res.data.isMock))
        .catch(err => console.error(err));
  }, []);

  const handleOpenCreateModal = () => dispatch(openCreateModal());

  const handleCancelAdd = () => {
    sessionStorage.clear();
    dispatch(signOut());
  };

  const setBlockPost = () => {
    AdminAPI.toggleIsMock({isMock: !isPosterBlocked})
        .then(res => setIsPostersBlocked(res.data.isMock));
  };

  return (
      <>
        {content === AdminContentEnum.INFO ?
            <>
              <div className={`admin-header`}>
                <div className="flex">
                  <h3>Панель администратора - Справка</h3>
                  <h4 className="pointer" onClick={() => setContent(AdminContentEnum.POSTERS)}>
                    <img src={arrows} alt="arrows back"/>
                    Назад к панели
                  </h4>
                </div>
                <h3 className="pointer" onClick={handleCancelAdd}>Выйти</h3>
              </div>
              <AdminInfo/>
            </>
            :
            <>
              <div className="admin-header">
                <div className="flex">
                  <h3>Панель администратора</h3>
                  <div className="infoCheckBox pointer" onClick={() => setContent(AdminContentEnum.INFO)}> ?</div>
                </div>
                <h3 className="pointer" onClick={handleCancelAdd}>Выйти</h3>
              </div>
              <div className="change">
                <div className="change-rowLeft">
                  <h4 className={`pointer ${postersNumber === 50 ? "hide" : ""}`} onClick={handleOpenCreateModal}>
                    Добавить
                  </h4>
                  <label htmlFor="blockPoster">
                    Заглушка {isPosterBlocked ? " включена" : " выключена"}
                  </label>
                  <input name="blockPoster"
                         className="pointer"
                         type="checkbox"
                         checked={isPosterBlocked}
                         onChange={setBlockPost}
                  />
                </div>
                <div>
                  {postersNumber}/50
                </div>
              </div>
            </>
        }
      </>
  );
};

export default AdminHeader;
