import React, {ReactComponentElement, ReactNode} from "react";
import 'moment/locale/ru';

interface PosterProps {
  posterStyle: object;
  currentPost: number;
  isLeftToggleClicked: boolean;
  isRightToggleClicked: boolean;
  postersLength: number;
  handleLeft: any;
  handleRight: any;
  children: ReactNode;
}


const Poster = ({
                  postersLength,
                  children,
                  currentPost,
                  handleLeft,
                  handleRight,
                  isLeftToggleClicked,
                  isRightToggleClicked,
                  posterStyle
                }: PosterProps) => {

  return (
      <div className="flex poster" style={posterStyle}>
        <div className="toggle">
          <button className={`${currentPost === 0 || isLeftToggleClicked
          || isRightToggleClicked ? "opacity06" : "pointer"} buttonLeft`}
                  onClick={handleLeft}
                  disabled={currentPost === 0 || isLeftToggleClicked || isRightToggleClicked}
          />
          <button className={`${currentPost === postersLength - 1 || isLeftToggleClicked
          || isRightToggleClicked ? "opacity06" : "pointer"} buttonRight`}
                  onClick={handleRight}
                  disabled={currentPost === postersLength - 1 || isLeftToggleClicked || isRightToggleClicked}
          />
        </div>
        <div className="posterRow flex">
          {children}
        </div>
      </div>
  );
}

export default Poster;