import React, {SetStateAction} from 'react';
import logoB from "../../../assests/images/logo.svg";
import arrows from "../../../assests/images/arrows.svg";
import {Dispatch} from "react";
import {useDispatch} from "react-redux";
import {switchAppToFabrika} from "../../../actions/PageAction";

interface Props {
  isPromoAnimation: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

const HeaderBottleBlock = ({isPromoAnimation, setIsLoading}: Props) => {

  const dispatch = useDispatch();

  const onSwitchApp = () => {
    dispatch(switchAppToFabrika());
    setIsLoading(true);
  }

  return (
    <div className={`promo__header ${isPromoAnimation ? "promo__animation" : ""}`}>
      <img src={logoB} alt="logo" className="imgB"/>
      <p>
        В дополнение к бару «Серебряная FABRIKA» мы создали для вас Место, которое уже перерасло в особенный и
        неповторимый формат, отвечающий самому изысканному вкусу-«Бутылочная» bar&store. Здесь, Вы сможете, в почти
        "кухонной", домашней атмосфере, неожиданно оказаться, как будто на корабле, отправляющемся в кругосветное
        плавание. Несущем на борту огромный запас бутылочек всех форм и размеров, наполненных напитками самых
        невообразимых вкусов. С личным поваром, который на ваших глазах готовит аппетитные закуски для идеального
        сопровождения Вашего напитка, а также подает блюда из собственной коптильни бара "Серебряная Fabrika".
        Приятного плаванья!
      </p>
      <div onClick={onSwitchApp}>
        В Фабрику
        <img src={arrows} alt="arrows"/>
      </div>
    </div>
  );
}


export default HeaderBottleBlock;
