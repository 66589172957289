import {GroupResDto} from "../api/dto/GroupResDto";
import {MenuState} from "../store/reducers/menuReducer";
import {Dispatch} from "redux";
import Api from "../api";
import {ProductResDto} from "../api/dto/ProductResDto";
import {GroupWithProductsResDto} from "../api/dto/GroupWithProductsResDto";
import WindowUtils from "../utils/WindowUtils";
import ApplicationEnum from "../utils/enums/ApplicationEnum";
import TogglerEnum from "../utils/enums/TogglerEnum";

export enum MenuActionType {
  SET_FETCHING = "SET_FETCHING",
  SET_GROUPS = "SET_GROUPS",
  SET_ERROR = "SET_ERROR",
  SET_ACTIVE_GROUP = "SET_ACTIVE_GROUP",
  SET_PRODUCTS_PAGE = "SET_PRODUCTS_PAGE",
  SET_GROUP_WITH_PRODUCTS = "SET_GROUP_WITH_PRODUCTS",
}

export interface MenuAction extends MenuState {
  type: MenuActionType,
}

export const menuFetchGroups = (application: ApplicationEnum, activeGroup: TogglerEnum) => {
  return async (dispatch: Dispatch) => {
    await fetch(dispatch, async () => {
      const groups: Array<GroupResDto> = await Api.menuApi.fetchBaseGroups(application, activeGroup);
      dispatch(setGroups(groups));
      WindowUtils.scrollToTop();
    });
  }
}

export const menuFetchProductsPage = (groupId: string, page: number = 0, size: number = 12) => {
  return async (dispatch: Dispatch) => {
    await fetch(dispatch, async () => {
      const products: Array<ProductResDto> = await Api.menuApi.fetchProductsPage(groupId, page, size);
      dispatch(setProducts(products));
      WindowUtils.scrollToTop();
    });
  }
}

export const menuFetchGroupsWithProducts = (groupId: string) => {
  return async (dispatch: Dispatch) => {
    await fetch(dispatch, async () => {
      const subgroupsWithProducts: GroupWithProductsResDto = await Api.menuApi.fetchSubgroupsWithProducts(groupId);
      dispatch(setGroupsWithProducts(subgroupsWithProducts));
      WindowUtils.scrollToTop();
    });
  }
}

export const menuSetActiveGroup = (activeGroup: GroupResDto) => {
  WindowUtils.scrollToTop();
  return {type: MenuActionType.SET_ACTIVE_GROUP, activeGroup};
}

const fetch = async (dispatch: Dispatch, body: () => void) => {
  dispatch(setLoading(true));
  dispatch(setError(null));
  try {
    await body();
  } catch (e) {
    dispatch(setError(e));
  }
  dispatch(setLoading(false));
}


const setGroups = (groups: Array<GroupResDto>) => ({type: MenuActionType.SET_GROUPS, baseGroups: groups});
const setLoading = (loading: boolean) => ({type: MenuActionType.SET_FETCHING, loading});
const setError = (error: Error | null) => ({type: MenuActionType.SET_ERROR, error});
const setProducts = (products: Array<ProductResDto>) => ({
  type: MenuActionType.SET_PRODUCTS_PAGE,
  products
});
const setGroupsWithProducts = (groupsWithProducts: GroupWithProductsResDto) => ({
  type: MenuActionType.SET_GROUP_WITH_PRODUCTS,
  groupsWithProducts
});