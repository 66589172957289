import {Body} from "../store/reducers/productsReducer";

export interface ProductAction {
    type: ProductActionType,
    kitchen: Body,
    bar: Body
}

export enum ProductActionType {
    SET_KITCHEN = "SET_KITCHEN",
    SET_BAR = "SET_BAR",
}

export const onSetKitchen = (kitchen: Body) => ({type: ProductActionType.SET_KITCHEN, kitchen})
export const onSetBar = (bar: Body) => ({type: ProductActionType.SET_BAR, bar})