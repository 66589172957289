
export const loadState = () => {

  try {
    const version = localStorage.getItem("version");
    let envVersion = process.env.REACT_APP_VERSION || "1.0.0-SNAPSHOT";
    if (!version || envVersion !== version) {
      localStorage.clear();
      localStorage.setItem("version", envVersion);
    } else {
      const serializedState = localStorage.getItem("state");
      if (serializedState === null) {
        return;
      }
      return JSON.parse(serializedState);
    }
  } catch (err) {
    console.error(err);
  }
};

interface State {
  page: object,
  adminPage: object,
  basket: any,
  poster: object
}

export const saveState = (state: State) => {
  try {
    const serializedState = JSON.stringify({
      page: state.page,
      adminPage: state.adminPage,
      basket: {
        basketFabrika: state.basket.basketFabrika,
        basketBottle: state.basket.basketBottle
      },
      poster: state.poster
    });
    localStorage.setItem("state", serializedState);
  } catch (err) {
    console.error(err);
  }
};