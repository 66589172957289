import axios, {AxiosInstance} from "axios";
import {AuthReqDto} from "../dto/AuthReqDto";

const {REACT_APP_ADMIN, REACT_APP_BACKEND_HOST} = process.env;

const adminURL: string = REACT_APP_ADMIN || "http://localhost:8888/api/v1/admin";
const baseURL: string = REACT_APP_BACKEND_HOST || "http://localhost:8888/api/v1";

class AxiosUtils {

  static getAdminInstance = (): AxiosInstance => {
    const auth: AuthReqDto = {
      username: sessionStorage.getItem("username") || "",
      password: sessionStorage.getItem("password") || "",
    }
    return axios.create({
      baseURL: adminURL,
      withCredentials: true,
      auth: auth
    });
  }

  static getBaseInstance = (): AxiosInstance => {
    return axios.create({
      baseURL: baseURL
    });
  }

  static addPagination = (page: number, size: number): string => {
    return `?page=${page}&size=${size}`;
  }

  static getBaseURL = (): string => {
    return baseURL;
  }
}

export default AxiosUtils;