import TogglerEnum from "../../../../utils/enums/TogglerEnum";
import React, {Dispatch, SetStateAction} from "react";

interface Props {
  active: TogglerEnum,
  currentToggler: TogglerEnum,
  setActive: Dispatch<SetStateAction<TogglerEnum>>,
  text: string
}

const TogglerElement: React.FC<Props> = ({active, setActive, currentToggler, text}) => {

  const getStyle = (toggler: TogglerEnum): string => {
    return active === toggler ? "large" : "small"
  }

  return (
    <div className={`text pointer ${getStyle(currentToggler)}`}
         onClick={() => setActive(currentToggler)}>
      {text}
    </div>
  );
}

export default TogglerElement;