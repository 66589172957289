import AxiosUtils from "./helpers/AxiosUtils";
import MockResDto from "./dto/MockResDto";
import {AxiosResponse} from "axios";
import {PosterResDto} from "./dto/AuthReqDto";

export default ({

  async isMock(): Promise<MockResDto> {
    const response: AxiosResponse<MockResDto> = await AxiosUtils.getBaseInstance().get('/poster/mock');
    return response.data;
  },

  async getPosters(page: number, size: number = 12): Promise<Array<PosterResDto>> {
    const response: AxiosResponse<Array<PosterResDto>> = await AxiosUtils
      .getBaseInstance()
      .get(`/poster` + AxiosUtils.addPagination(page, size));
    return response.data;
  },

  getImageUrl(filename: string): string {
    if (!filename) {
      throw Error("filename is null");
    }
    return `${AxiosUtils.getBaseURL()}/image/${filename}`;
  },
});