import AxiosUtils from "./helpers/AxiosUtils";
import ApplicationEnum from "../utils/enums/ApplicationEnum";
import TogglerEnum from "../utils/enums/TogglerEnum";
import {AxiosResponse} from "axios";
import {GroupResDto} from "./dto/GroupResDto";
import {ProductResDto} from "./dto/ProductResDto";
import {GroupWithProductsResDto} from "./dto/GroupWithProductsResDto";

export const menuApi = ({

  async fetchBaseGroups(application: ApplicationEnum, group: TogglerEnum): Promise<Array<GroupResDto>> {
    if (group === TogglerEnum.NONE) {
      throw new Error(`group shouldn't be ${TogglerEnum.NONE}`);
    }

    const response: AxiosResponse<Array<GroupResDto>> = await AxiosUtils
      .getBaseInstance()
      .get(`/iiko/group/${application}/${group}`);

    return response.data;
  },

  async fetchSubgroupsWithProducts(groupId: string): Promise<GroupWithProductsResDto> {
    const response: AxiosResponse<GroupWithProductsResDto> = await AxiosUtils
      .getBaseInstance()
      .get(`/iiko/group/${groupId}`);

    return response.data;
  },

  async fetchProductsPage(groupId: string, page: number, size: number = 12): Promise<Array<ProductResDto>> {
    const response: AxiosResponse<Array<ProductResDto>> = await AxiosUtils
      .getBaseInstance()
      .get(`/iiko/product/${groupId}/${AxiosUtils.addPagination(page, size)}`);

    return response.data;
  },

  async getGroupsByTopGroupIikoId(iikoId: string, needProducts: boolean) {
    if (!iikoId) {
      throw Error("topGroup is null");
    }
    const response = await AxiosUtils
      .getBaseInstance()
      .get(`/iiko/nomenclature/group/all/${iikoId}?needProducts=${needProducts}`);
    return response.data;
  },

  async getProductsByTopGroupIikoId(iikoId: string, page: number, size: number = 15) {
    if (!iikoId) {
      throw Error("topGroup is null");
    }
    const response = await AxiosUtils.getBaseInstance().get(`/iiko/nomenclature/product/all/${iikoId}`
      + AxiosUtils.addPagination((page - 1), size));
    return response.data;
  },
});