import {GetGroupDto} from "../../api/dto/GetGroupDto";
import {TogglerAction, TogglerActionType} from "../../actions/TogglerAction";

interface TogglerState {
  groups: Array<GetGroupDto>
}

const initialState: TogglerState = {
  groups: []
}

const togglerReducer = (state = initialState, action: TogglerAction) => {
  switch (action.type) {
    case TogglerActionType.SET_GROUPS: {
      return {...state, groups: action.groups}
    }
    default: {
      return state
    }
  }
}

export default togglerReducer;