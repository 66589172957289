import React from "react";
import {GroupResDto} from "../../../api/dto/GroupResDto";

interface Props {
  group: GroupResDto,
  activeGroup: GroupResDto | null,
  setActiveGroup: (group: GroupResDto) => void,
}

const MenuGroupsItem: React.FC<Props> = ({group, activeGroup, setActiveGroup}) => {

  return (
    <div className={`group pointer ${activeGroup === group ? "active" : ""}`}
         onClick={() => setActiveGroup(group)}>
      {group.name}
    </div>
  );

}


export default MenuGroupsItem;