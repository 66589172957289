import React from 'react';
import PhoneNumber from "../contacts/PhoneNumber";
import {useSelector} from "react-redux";
import VkontakteLink from "../contacts/VkontakteLink";
import InstagramLink from "../contacts/InstagramLink";
import AddressLink from "../contacts/AddressLink";
import {RootState} from "../../../store/reducers";
import ApplicationEnum from "../../../utils/enums/ApplicationEnum";
import PageEnum from "../../../utils/enums/PageEnum";

const Footer = () => {

  const {application, page} = useSelector((state: RootState) => state.page);

  return (
    <footer className={`footer ${page === PageEnum.LANDING ? "footer-landing" : "footer-base"}`}>
      <div>
        <div className="footer-content">
          <p>Адрес:</p>
          <AddressLink/>
        </div>
        <div className="footer-content">
          <p>Телефон:</p>
          <p>
            <PhoneNumber active={false}/>
          </p>
        </div>
        <div className="footer-content">
          <p>Режим работы:</p>
          {
            (
              application == ApplicationEnum.BOTTLE && <p>Пн-Вс: <span className="opacity06">с 12:00 до 01:00</span></p>
            )
            ||
            (
              application == ApplicationEnum.FABRIKA &&
              <>
                <p>Вс-Чт: <span className="opacity06">с 15:00 до 03:00</span></p>
                <p>Пт-Сб: <span className="opacity06">с 15:00 до 06:00</span></p>
              </>
            )
          }
        </div>
        <div className="footer-content">
          <p className="copy-write opacity06">ООО "Территория"</p>
          <p className="copy-write opacity06">© Серебряная FABRIKA 2016 - {new Date().getFullYear()} </p>
        </div>
      </div>
      <div className="icons">
        <div className="icon icon-instagram">
          <InstagramLink/>
        </div>
        <div className="icon icon-vkontakte">
          <VkontakteLink/>
        </div>
      </div>
    </footer>
  );
};

export default Footer;