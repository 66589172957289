import React from 'react';
import "../../assests/styles/admin.scss";

const AdminInfo = () => {
  return (
      <div>
        <p>
          Равным образом укрепление и развитие структуры представляет собой интересный эксперимент проверки
          соответствующий условий активизации. Значимость этих проблем настолько очевидна, что новая модель
          организационной деятельности требуют от нас анализа новых предложений. Значимость этих проблем настолько
          очевидна, что консультация с широким активом позволяет выполнять важные задания по разработке новых
          предложений. Товарищи! реализация намеченных плановых заданий позволяет выполнять важные задания по
          разработке существенных финансовых и административных условий. Идейные соображения высшего порядка, а также
          дальнейшее развитие различных форм деятельности требуют определения и уточнения системы обучения кадров,
          соответствует насущным потребностям.
        </p>
        <p>
          Идейные соображения высшего порядка, а также сложившаяся структура организации требуют от нас анализа новых
          предложений. Задача организации, в особенности же консультация с широким активом позволяет оценить значение
          направлений прогрессивного развития. Таким образом укрепление и развитие структуры играет важную роль в
          формировании направлений прогрессивного развития. Равным образом консультация с широким активом требуют от
          нас анализа систем массового участия. Не следует, однако забывать, что постоянный количественный рост и
          сфера нашей активности играет важную роль в формировании модели развития. Товарищи! дальнейшее развитие
          различных форм деятельности требуют определения и уточнения дальнейших направлений развития.
        </p>
        <p>
          Значимость этих проблем настолько очевидна, что начало повседневной работы по формированию позиции требуют
          определения и уточнения направлений прогрессивного развития. Товарищи! новая модель организационной
          деятельности в значительной степени обуславливает создание позиций, занимаемых участниками в отношении
          поставленных задач. Идейные соображения высшего порядка, а также постоянный количественный рост и сфера
          нашей активности в значительной степени обуславливает создание дальнейших направлений развития. Равным
          образом сложившаяся структура организации представляет собой интересный эксперимент проверки соответствующий
          условий активизации. Разнообразный и богатый опыт укрепление и развитие структуры способствует подготовки и
          реализации соответствующий условий активизации.
        </p>
      </div>
  );
}

export default AdminInfo;