import React from 'react';

interface Props {
  value: number,
  handleMinus: () => void,
  handlePlus: () => void,
}

const Counter:React.FC<Props> = ({value, handleMinus, handlePlus}) => {

  return (
    <div className={"counter"}>
      <div className={"minus"} onClick={handleMinus}>-</div>
      <div className={"value"}>{value}</div>
      <div className={"plus"} onClick={handlePlus}>+</div>
    </div>
  );
}

export default Counter;