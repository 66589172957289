import {PosterAction, PosterActionType} from "../../actions/PosterAction";
import {PosterResDto} from "../../api/dto/AuthReqDto";

interface PosterState {
  isMock: boolean,
  error: Error | null,
  posters: Array<PosterResDto>
}

const initialState: PosterState = {
  isMock: true,
  error: null,
  posters: [],
}

export default (state: PosterState = initialState, action: PosterAction) => {
  switch (action.type) {
    case PosterActionType.SET_MOCK:
     return {...state, isMock: action.isMock}
    case PosterActionType.SET_ERROR:
      return {...state, error: action.error}
    case PosterActionType.SET_POSTERS:
      return {...state, posters: action.posters}
    default:
      return state;
  }
}