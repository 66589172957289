import React from 'react';
import {NavLink} from "react-router-dom";
import back from "../../assests/images/arrow-left.svg";

interface TitleWithBackProps {
    title: string,
    backName: string
    secondBackname: string,
    backPath: string
}

const TitleWithBack = ({title,secondBackname, backName, backPath}: TitleWithBackProps) => {
    return (
        <div className="conditions__header">
            <div className="opacity06">
                <NavLink to={backPath}>
                    <img className="img" src={back} alt="back"/>
                    <p className="p">{backName}</p>
                    <p className={"p1"}>{secondBackname}</p>
                </NavLink>
            </div>
            <div style={{display: "flex",alignItems: "center"}}><p style={{display: "block"}}>{title}</p></div>
            <div/>
        </div>
    );
}

export default TitleWithBack;