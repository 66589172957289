import React, {useState} from "react";
import TogglerEnum from "../../../utils/enums/TogglerEnum";
import Toggler from "./toggler/Toggler";
import MenuGroups from "./MenuGroups";

const MenuNav: React.FC = () => {

  const [active, setActive] = useState<TogglerEnum>(TogglerEnum.KITCHEN);

  return (
    <nav>
      <Toggler active={active} setActive={setActive}/>
      <MenuGroups active={active}/>
    </nav>
  );
}

export default MenuNav;