import React, {ReactNode} from 'react';
import arrowUp from "../../assests/images/arrowUp.svg";
import arrowDown from "../../assests/images/arrowDown.svg";
import cross from "../../assests/images/deleteCross.svg";
import {AdminAPI} from "../../api/api";
import {useDispatch} from "react-redux";
import {openDeleteModal, setCurrentId} from "../../actions/AdminAction";
import PostInputs from "./PostInputs";
import {PosterResDto} from "../../api/dto/AuthReqDto";

interface PostInputsContainerProps {
  fetchPosters: () => void,
  id: number,
  index: number,
  poster: PosterResDto
}

const PostInputsContainer = ({id, index, poster, fetchPosters}: PostInputsContainerProps) => {

  const dispatch = useDispatch();

  const handleDelete = (id: number) => {
    dispatch(setCurrentId(id));
    dispatch(openDeleteModal());
  }

  const handleDown = (id: number) => {
    AdminAPI.swipeDown(id)
        .then(res => {
          fetchPosters();
        })
        .catch(err => {
          console.log(err);
        });
  }

  const handleUp = (id: number) => {
    AdminAPI.swipeUp(id)
        .then(function (response) {
          fetchPosters();
        })
        .catch(function (error) {
          console.log(error);
        });
  }

  return (
      <>
        <div className="line-after">
          <h3>№{index} афиша<span/></h3>
          <img src={arrowUp} onClick={() => handleUp(id)} alt="arrow up"/>
          <img src={arrowDown} onClick={() => handleDown(id)} alt="arrow down"/>
          <img src={cross} onClick={() => handleDelete(id)} alt="delete cross"/>
        </div>
        <PostInputs key={poster.id}
                    id={poster.id}
                    title={poster.title}
                    description={poster.description}
                    imageName={poster.imageName}
                    posterLink={poster.posterLink}
                    date={poster.date}
                    forceUpdatePosts={fetchPosters}
        />
      </>
  );
}

export default PostInputsContainer;