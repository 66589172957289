import React, {Dispatch, SetStateAction} from 'react';
import logoF from "../../../assests/images/header_logo.svg";
import arrows from "../../../assests/images/arrows.svg";
import {useDispatch} from "react-redux";
import {switchAppToBottle} from "../../../actions/PageAction";

interface Props {
  isPromoAnimation: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

const HeaderFabrikaBlock = ({isPromoAnimation, setIsLoading}: Props) => {

  const dispatch = useDispatch();

  const onSwitchApp = () => {
    dispatch(switchAppToBottle());
    setIsLoading(true);
  }

  return (
    <section className={`promo__header ${isPromoAnimation ? "promo__animation" : ""}`}>
      <img src={logoF} alt="Silver Factory logo" className="imgF"/>
      <p>
        «Серебряная FABRIKA» - музыкальный Bar&Grill в центре города Курск, где всем правит свобода творчества
        и драйва.
      </p>
      <p>
        У нас Вас ждет некий лофтовый район – FABRIKA непринужденной атмосферы и абсолютного смешения в интерьере,
        меню бара, кухни, музыки. Богатая пивная карта, регулярно обновляющиеся сорта на кранах, изысканный выбор
        редких и уникальных вин, буйное разнообразие крепкого алкоголя, авторские коктейли и твисты на классику…
        Идеальной парой к этому станет наша авторская кухня: мясо и рыба на углях, колбаски ручной работы, собственная
        коптильня Smoke BBQ и многое другое...
      </p>
      <p>
        Мы перевернем все с ног на голову и приложим все усилия, чтобы Вы получили от этого максимум удовольствия!
      </p>
      <div onClick={onSwitchApp}>
        <span>В Бутылочную</span>
        <img src={arrows} alt="arrows"/>
      </div>
    </section>
  );
};


export default HeaderFabrikaBlock;
