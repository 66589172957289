import React from 'react';

const NoPosts = () => {
  return (
      <div className="noPosts">
        <p>Вы не добавили ни одной афиши :(</p>
        <p>Нажмите на кнопку “Добавить”<br/>Для изучения “Справки” нажмите на иконку “вопрос”</p>
      </div>
  );
}

export default NoPosts;