import React from 'react';
import {NavLink} from "react-router-dom";
import logoFabrika from '../../assests/images/header_logo.svg';
import logoBottle from '../../assests/images/logo_bottle.svg';
import Dates from "./Dates";
import Basket from "./basket/Basket";
import PhoneNumber from "../common/contacts/PhoneNumber";
import {useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import ApplicationEnum from "../../utils/enums/ApplicationEnum";
import PageEnum from "../../utils/enums/PageEnum";

const Header: React.FC = () => {

    const {application, page} = useSelector((state: RootState) => state.page);

        if (page === PageEnum.MENU) {
            return (
                <>
                    <div className={"header_bg"}></div>
                    <header className="header">
                        <div className="header_body">
                            <div className="flex">
                                <NavLink to="/">
                                    {
                                        (application === ApplicationEnum.FABRIKA &&
                                            <img className={"header-image"} src={logoFabrika} alt="logo"/>)
                                        ||
                                        (application === ApplicationEnum.BOTTLE &&
                                            <img className={"header-image"} src={logoBottle} alt="logo"/>)
                                    }
                                </NavLink>

                                <div className="info">
                                    <div className="phone">
                                        <span className="opacity06">Звоните:&nbsp;&nbsp;</span>
                                        <PhoneNumber active={true}/>
                                    </div>
                                    <Dates/>
                                </div>
                            </div>

                            <Basket/>
                        </div>
                    </header>
                </>
            );
        }

        return (
            <header className="header_body">
                <div className="flex">
                    <NavLink to="/">
                        {
                            (application === ApplicationEnum.FABRIKA && <img src={logoFabrika} alt="logo"/>)
                            ||
                            (application === ApplicationEnum.BOTTLE && <img src={logoBottle} alt="logo"/>)
                        }
                    </NavLink>

                    <div className="info">
                        <div className="phone">
                            <span className="opacity06">Звоните:&nbsp;&nbsp;</span>
                            <PhoneNumber active={true}/>
                        </div>
                        <Dates/>
                    </div>
                </div>

                <Basket/>
            </header>

        );

    }
;

export default Header;