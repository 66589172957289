import React from 'react';
import menuPDF from "../../../assests/menu.pdf";
import PromoButton from "../../common/PromoButton";
import {NavLink} from "react-router-dom";

const FooterBottleBlock = () => {
  return (
      <div className="promo__footerB">
        <p>
          Блюда от бара «Серебряная FABRIKA», которые повар готовит прямо на ваших глазах, приветливый персонал и
          разумные цены при исключительно высоком качестве - гарантируют приятное времяпровождение.
        </p>
        <NavLink to={"/menu"}> <PromoButton text={"ПЕРЕЙТИ В МЕНЮ"}/> </NavLink>
      </div>
  );
}

export default FooterBottleBlock;