import {ProductDto} from '../../api/dto/GetProductDto';
import {BasketAction, BasketActionType, CountedProduct} from "../../actions/BasketAction";
import ApplicationEnum from "../../utils/enums/ApplicationEnum";
import {ProductResDto} from "../../api/dto/ProductResDto";
import {stat} from "fs";

interface Body {
  products: Array<ProductDto>,
  totalPrice: number,
  totalDishes: number,
}

interface Body2 {
  products: Array<CountedProduct>,
  price: number,
  dishes: number,
}

const bodyInitialState: Body = {
  products: [],
  totalPrice: 0,
  totalDishes: 0,
};

const bodyInitialState2: Body2 = {
  products: [],
  price: 0,
  dishes: 0,
};

interface BasketState {
  fabrika: Body,
  bottle: Body,
  basketFabrika: Body2,
  basketBottle: Body2,
  isActive: boolean
}

const initialState: BasketState = {
  fabrika: bodyInitialState,
  bottle: bodyInitialState,
  basketFabrika: bodyInitialState2,
  basketBottle: bodyInitialState2,
  isActive: false
}


export default (state: BasketState = initialState, action: BasketAction): BasketState => {
  switch (action.type) {
    case BasketActionType.TOGGLE_ACTIVE: {
      return {...state, isActive: action.isActive}
    }
    case BasketActionType.ADD_PRODUCT: {
      let products = null;
      if (action.appType === ApplicationEnum.FABRIKA) {
        products = [...state.fabrika.products, action.product];
      } else {
        products = [...state.bottle.products, action.product];
      }
      const totalPrice = products.map(p => p.countItems * p.price).reduce((prev, cur) => prev + cur, 0);
      const totalDishes = products.map(p => p.countItems).reduce((prev, cur) => prev + cur, 0);
      if (action.appType == ApplicationEnum.FABRIKA) {
        return {...state, fabrika: {...state.fabrika, products, totalPrice, totalDishes}};
      } else {
        return {...state, bottle: {products, totalPrice, totalDishes}};
      }
    }

    case BasketActionType.REMOVE_PRODUCT: {
      let products = null;
      if (action.appType === ApplicationEnum.FABRIKA) {
        products = state.fabrika.products.filter(product => product.name !== action.product.name);
      } else {
        products = state.bottle.products.filter(product => product.name !== action.product.name);
      }
      const totalPrice = products.map(p => p.countItems * p.price).reduce((prev, cur) => prev + cur, 0);
      const totalDishes = products.map(p => p.countItems).reduce((prev, cur) => prev + cur, 0);
      if (action.appType === ApplicationEnum.FABRIKA) {
        return {...state, fabrika: {products, totalPrice, totalDishes}};
      } else {
        return {...state, bottle: {products, totalPrice, totalDishes}};
      }
    }

    case BasketActionType.ADD_PRODUCT_ITEM: {
      let product = null;
      if (action.appType === ApplicationEnum.FABRIKA) {
        product = state.fabrika.products.find(product => action.product.name === product.name);
      } else {
        product = state.bottle.products.find(product => action.product.name === product.name);
      }
      if (!product) return state;
      product.countItems++;
      if (action.appType === ApplicationEnum.FABRIKA) {
        return {
          ...state,
          fabrika: {
            totalPrice: state.fabrika.totalPrice + product.price,
            totalDishes: state.fabrika.totalDishes + 1,
            products: state.fabrika.products
          }
        };
      } else {
        return {
          ...state,
          bottle: {
            totalPrice: state.bottle.totalPrice + product.price,
            totalDishes: state.bottle.totalDishes + 1,
            products: state.bottle.products
          }
        };
      }
    }

    case BasketActionType.REMOVE_PRODUCT_ITEM: {
      let product = null;
      if (action.appType === ApplicationEnum.FABRIKA) {
        product = state.fabrika.products.find(product => action.product.name === product.name);
      } else {
        product = state.bottle.products.find(product => action.product.name === product.name);
      }
      if (!product) return state;
      product.countItems--;
      if (action.appType === ApplicationEnum.FABRIKA) {
        return {
          ...state,
          fabrika: {
            totalPrice: state.fabrika.totalPrice - product.price,
            totalDishes: state.fabrika.totalDishes - 1,
            products: state.fabrika.products
          }
        };
      } else {
        return {
          ...state,
          bottle: {
            totalPrice: state.bottle.totalPrice - product.price,
            totalDishes: state.bottle.totalDishes - 1,
            products: state.bottle.products
          }
        };
      }
    }

    case BasketActionType.CLEAR: {
      if (action.appType === ApplicationEnum.FABRIKA) {
        return {
          ...state,
          fabrika: initialState.fabrika,
          bottle: state.bottle
        }
      } else {
        return {
          ...state,
          fabrika: state.fabrika,
          bottle: initialState.bottle
        }
      }
    }

    case BasketActionType.PUSH_PRODUCT_FABRIKA: {
      const products = push(state.basketFabrika.products, action.product2);
      return {...state, basketFabrika: {products,price: getTotalPrice(products), dishes: getTotalDishes(products)}};
    }

    case BasketActionType.POP_PRODUCT_FABRIKA: {
      const products = pop(state.basketFabrika.products, action.product2);
      if (!products) return state;
      return {...state, basketFabrika: {products,price: getTotalPrice(products), dishes: getTotalDishes(products)}};
    }

    case BasketActionType.REMOVE_PRODUCT_FABRIKA: {
      const products = state.basketFabrika.products.filter(value => value.product.name!==action.product2.name);
      return {...state, basketFabrika: {products,price: getTotalPrice(products), dishes: getTotalDishes(products)}}
    }

    case BasketActionType.PUSH_PRODUCT_BOTTLE: {
      const products = push(state.basketBottle.products, action.product2);
      return {...state, basketBottle: {products,price: getTotalPrice(products), dishes: getTotalDishes(products)}};
    }

    case BasketActionType.POP_PRODUCT_BOTTLE: {
      const products = pop(state.basketBottle.products, action.product2);
      if (!products) return state;
      return {...state, basketBottle: {products,price: getTotalPrice(products), dishes: getTotalDishes(products)}};
    }

    case BasketActionType.REMOVE_PRODUCT_FABRIKA: {
      const products = state.basketBottle.products.filter(value => value.product.name!==action.product2.name);
      return {...state, basketBottle: {products,price: getTotalPrice(products), dishes: getTotalDishes(products)}}
    }

    default: {
      return state;
    }
  }
}


const push = (products: Array<CountedProduct>, searchProduct: ProductResDto): Array<CountedProduct> => {
  const product: CountedProduct | undefined = products.find(p => p.product.iikoId === searchProduct.iikoId);
  if (!product) {
    const countedProduct: CountedProduct = {product: searchProduct, amount: 1};
    return [...products, countedProduct];
  }

  return products.map(p => {
    if (p.product.iikoId === searchProduct.iikoId) {
      p.amount += 1;
    }
    return p;
  });
}

const pop = (products: Array<CountedProduct>, searchProduct: ProductResDto): Array<CountedProduct> | null => {
  const product: CountedProduct | undefined = products.find(p => p.product.iikoId === searchProduct.iikoId);
  if (!product) return null;

  if (product.amount > 1) {
    return products.map(p => {
      if (p.product.iikoId === searchProduct.iikoId) {
        p.amount -= 1;
      }
      return p;
    });
  }

  return products.filter(p => p.product.iikoId !== searchProduct.iikoId);
}

const getTotalPrice = (products: Array<CountedProduct>) => {
  let totalPrice = 0;
  products.map(product => {
    for(let i=1;i<=product.amount;i++) {
      totalPrice+=product.product.price;
    }
  })
  return totalPrice;
}

const getTotalDishes = (products: Array<CountedProduct>) => {
  let totalDishes = 0;
  products.map(product => {
    totalDishes += product.amount;
  })
  return totalDishes;
}