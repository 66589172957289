import React from 'react';
import Header from "../components/header/Header";
import Footer from "../components/common/footer/Footer";
import MenuNav from "../components/menu/nav/MenuNav";
import usePage from "../utils/hooks/usePage";
import PageEnum from "../utils/enums/PageEnum";
import MenuProducts from "../components/menu/content/MenuProducts";

const Menu: React.FC = () => {

    usePage(PageEnum.MENU);

    return (
        <div className="container menu">
            <Header/>
            <MenuNav/>
            <MenuProducts/>
            <Footer/>
        </div>
    );
}

export default Menu;