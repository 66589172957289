import React from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../../store/reducers";
import {GroupResDto} from "../../../api/dto/GroupResDto";
import MenuSubgroups from "./MenuSubgroups";

interface Props {
  parentGroup: GroupResDto
}

const MenuSubgroupWithProduct: React.FC<Props> = ({parentGroup}) => {

  const {groupsWithProducts} = useSelector((state: RootState) => state.menu);

  return (
    <>
      {
        groupsWithProducts?.map((groups, key) =>
          <MenuSubgroups key={key}
                         parent={null}
                         parentGroup={parentGroup.name.toUpperCase()}
                         active={key === 0}
                         groupWithSubgroups={groups}/>
        )
      }
    </>
  );
}

export default MenuSubgroupWithProduct;