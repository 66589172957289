import {ModalKitchenAction, ModalKitchenActionType} from "../../actions/ModalKitchenAction";

export interface Body {
  name: string,
  iikoId: string,
  isKitchen: boolean
}

export const bodyInitialState: Body = {
  name: "",
  iikoId: "",
  isKitchen: false
}

interface ModalKitchenState {
  topGroupNameKitchen: Body,
  topGroupNameBar: Body
}

const initialState: ModalKitchenState = {
  topGroupNameKitchen: bodyInitialState,
  topGroupNameBar: bodyInitialState
};

const modalKitchenReducer = (state = initialState, action: ModalKitchenAction) => {
  switch (action.type) {
    case ModalKitchenActionType.SET_TOP_GROUP_NAME_KITCHEN: {
      return {...state, topGroupNameKitchen: action.topGroupNameKitchen}
    }
    case ModalKitchenActionType.SET_TOP_GROUP_NAME_BAR: {
      return {...state, topGroupNameBar: action.topGroupNameBar}
    }
    default: {
      return state
    }
  }
};


export default modalKitchenReducer;
