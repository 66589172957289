import React, {useState} from 'react';
import {Field, Form, Formik, FormikProps} from "formik";
import ImgInput from "./ImgInput";
import moment from "moment";
import {AdminAPI} from "../../api/api";

interface FormProps {
  date: string,
  description: string,
  posterLink: string,
  title: string,
}

interface PostInputsProps {
  id: number,
  imageName: string,
  date: Date,
  description: string,
  posterLink: string,
  title: string,
  forceUpdatePosts: () => void,
}

const PostInputs = ({id, date, description, imageName, title, posterLink, forceUpdatePosts}: PostInputsProps) => {

  const [newImageName, setNewImageName] = useState(imageName);
  const [newImgFile, setNewImgFile] = useState<string | Blob>("");
  const initLength = {title: title.length, description: description.length};
  const posterDate: string = moment(date).format("DD.MM.YYYY");

  const handleSubmit = (values: FormProps) => {
    const dateParts: any = values.date.toString().split(".");
    const date: any = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    const newData = moment(date);

    const posterData = {
      date: newData,
      title: values.title,
      description: values.description,
      posterLink: values.posterLink,
    }
    const json = JSON.stringify(posterData);
    const blob = new Blob([json], {
      type: 'application/json'
    });

    const formData = new FormData();
    if (newImgFile) {
      formData.append("file", newImgFile, newImageName);
    }
    formData.append("poster", blob);

    AdminAPI.updatePoster(id, formData)
        .then(res => forceUpdatePosts())
        .catch(err => console.log(err));
  };

  return (
      <Formik initialValues={{
        title: title,
        description: description,
        date: posterDate,
        posterLink: posterLink,
      }}
              onSubmit={handleSubmit}
      >
        {(formik: FormikProps<FormProps>) => {
          const isDataValid = moment(formik.values.date, 'DD.MM.YYYY', true).isValid();
          const length = {
            title: formik.values.title.length,
            date: formik.values.date.length,
            description: formik.values.description.length,
          }
          const isChange = (formik.values.title === title
              && formik.values.description === description
              && formik.values.posterLink === posterLink
              && formik.values.date === posterDate
              && imageName === newImageName
          );
          const error = {title: length.title > 30, description: length.description > 200, date: length.date < 1};
          const disableSubmit = error.title || error.description || error.date || !isDataValid || isChange;
          const disableReset = isChange;

          return (
              <Form onSubmit={formik.handleSubmit}>
                <div className={`textInput ${length.title > 30 ? "err" : ""}`}>
                  <label htmlFor="title">
                    {length.title === 0 ? initLength.title : length.title}/30
                  </label>
                  <Field name="title"/>
                </div>
                <div className={`textInput ${length.description > 200 ? "err" : ""}`}>
                  <label htmlFor="description">
                    {length.description === 0 ? initLength.description : length.description}/200
                  </label>
                  <Field name="description"/>
                </div>
                <div className={`textInput ${!isDataValid ? "err" : ""}`}>
                  <Field name="date"/>
                </div>
                <div className="textInput">
                  <Field name="posterLink"/>
                </div>
                <ImgInput setNewImgFile={setNewImgFile} setNewImageName={setNewImageName} newImageName={newImageName}/>
                <div className="line-after">
                  <div className="flex">
                    <button type="submit" className={`${disableSubmit ? "disable" : ""}`} disabled={disableSubmit}>
                      Сохранить
                    </button>
                    <button type="reset"
                            disabled={disableReset}
                            className={`${disableReset ? "disable" : ""}`}
                            onClick={() => {
                              formik.resetForm();
                              setNewImageName(imageName);
                              setNewImgFile("");
                            }}
                    >
                      Сбросить
                    </button>
                  </div>
                  {
                    formik.isSubmitting
                        ?
                        <h4>
                          Изменения сохранены<span/>
                        </h4>
                        :
                        <h4 className="notSaveError">
                          {formik.dirty || imageName !== newImageName ? "Изменения не сохранены" : ""}<span/>
                        </h4>
                  }

                </div>
              </Form>
          );
        }}
      </Formik>
  );
};

export default PostInputs;