import React from 'react';
import ApplicationEnum from "../../../utils/enums/ApplicationEnum";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/reducers";

function AddressLink() {

  const {application} = useSelector((state: RootState) => state.page);

  function mapsSelectorFactory() {
    if /* if we're on iOS, open in Apple Maps */
    ((navigator.platform.indexOf("iPhone") != -1) ||
        (navigator.platform.indexOf("iPad") != -1) ||
        (navigator.platform.indexOf("iPod") != -1))
      window.open("maps://maps.google.com/maps?daddr=51.745079,36.195217&amp;ll=");
    else /* else use Google */
      window.open("https://maps.google.com/maps?daddr=51.745079,36.195217&amp;ll=");
  }

  function mapsSelectorBottle() {
    if /* if we're on iOS, open in Apple Maps */
    ((navigator.platform.indexOf("iPhone") != -1) ||
        (navigator.platform.indexOf("iPad") != -1) ||
        (navigator.platform.indexOf("iPod") != -1))
      window.open("maps://maps.google.com/maps?daddr=51.741522,36.191768&amp;ll=");
    else /* else use Google */
      window.open("https://maps.google.com/maps?daddr=51.741522,36.191768&amp;ll=");
  }

  return (
      <div>
        {
          (application === ApplicationEnum.FABRIKA &&
              <p className="opacity06 underline pointer" onClick={mapsSelectorFactory}>Курск, Ленина 77Б</p>)
          ||
          (application === ApplicationEnum.BOTTLE &&
              <p className="opacity06 underline pointer" onClick={mapsSelectorBottle}>Курск, Садовая 10а</p>)
        }
      </div>
  );
}

export default AddressLink;