import React from 'react';
import PromoButton from "../../../common/PromoButton";

interface PosterItemProps {
  classList: any,
  backgroundStyle: string,
  handleClick:  React.MouseEventHandler<HTMLDivElement>,
  day: string,
  month: string,
  year: string,
  title: string,
  date: string,
  smallerDescription: string,
  url: string
}

const PosterItem = ({classList, backgroundStyle, handleClick,
                      day, month, year, title, date, smallerDescription, url}: PosterItemProps) => {

  return (
      <div className={classList} style={{backgroundImage: `${backgroundStyle}`}} onClick={handleClick}>
        {/*fixMe: Сделать стили самостоятельными с нормальными названиями*/}
        <div className="activePost-label">
          <h1>{day}</h1>
          <h4>{month}</h4>
          <p>{year}</p>
        </div>
        <div className="post-info">
          <h1 className="non-activePost non-activePost-h1">{title}</h1>
          <h1 className="activePost activePost-h1">{title}</h1>
          <p className="non-activePost non-activePost-p">{date}</p>
          <p className="activePost activePost-p">{smallerDescription}</p>
          <a className="activePost" href={url} target="_blank">
            <PromoButton text={"КУПИТЬ БИЛЕТ"}/>
          </a>
        </div>
      </div>
  );
};

export default PosterItem;