import {MenuAction, MenuActionType} from "../../actions/MenuAction";
import {GroupResDto} from "../../api/dto/GroupResDto";
import {ProductResDto} from "../../api/dto/ProductResDto";
import {GroupWithProductsResDto} from "../../api/dto/GroupWithProductsResDto";

export interface MenuState {
  loading: boolean,
  baseGroups: Array<GroupResDto>,
  error: Error | null,
  activeGroup: GroupResDto | null,
  products: Array<ProductResDto> | null,
  groupsWithProducts: Array<GroupWithProductsResDto> | null
}

const initialState: MenuState = {
  loading: false,
  baseGroups: [],
  error: null,
  activeGroup: null,
  products: null,
  groupsWithProducts: null
}

export default (state = initialState, action: MenuAction): MenuState => {
  switch (action.type) {
    case MenuActionType.SET_FETCHING:
      return {...state, loading: action.loading};
    case MenuActionType.SET_ERROR:
      return {...state, error: action.error};
    case MenuActionType.SET_GROUPS:
      return {...state, baseGroups: action.baseGroups};
    case MenuActionType.SET_ACTIVE_GROUP:
      return {...state, activeGroup: action.activeGroup};
    case MenuActionType.SET_PRODUCTS_PAGE:
      return {...state, products: action.products};
    case MenuActionType.SET_GROUP_WITH_PRODUCTS:
      return {...state, groupsWithProducts: action.groupsWithProducts};
    default:
      return state;
  }
}