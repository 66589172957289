import {PosterResDto} from "../../api/dto/AuthReqDto";
import {AdminModalEnum} from "../../utils/enums/AdminModalEnum";
import {ActionType, AuthAction} from "../../actions/AdminAction";

export interface StateType {
  isAuth: boolean;
  currentModal: AdminModalEnum.NONE,
  posts: PosterResDto[],
  currentId: number | null,
}

const initialState: StateType = {
  isAuth: false,
  currentModal: AdminModalEnum.NONE,
  posts: [],
  currentId: null,
}

const adminReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case AuthAction.SIGN_IN:
      return {...state, isAuth: true};
    case AuthAction.SIGN_OUT:
      localStorage.clear();
      return {...state, isAuth: false, posts: initialState};
    case AuthAction.OPEN_CREATE_MODAL:
      return {...state, currentModal: AdminModalEnum.CREATE};
    case AuthAction.OPEN_DELETE_MODAL:
      return {...state, currentModal: AdminModalEnum.DELETE};
    case AuthAction.CLOSE_MODALS:
      return {...state, currentModal: AdminModalEnum.NONE};
    case AuthAction.SET_POSTERS:
      return {...state, posts: action.posts};
    case AuthAction.SET_POSTER_ID:
      return {...state, currentId: action.currentId};
    default:
      return state;
  }
};

export default adminReducer;