import React, {Dispatch, SetStateAction} from "react";
import TogglerEnum from "../../../../utils/enums/TogglerEnum";
import TogglerElement from "./TogglerElement";

interface Props {
  active: TogglerEnum,
  setActive: Dispatch<SetStateAction<TogglerEnum>>
}

const Toggler: React.FC<Props> = ({active, setActive}) => {

  return (
    <div className="newToggler">
      <TogglerElement text={"КУХНЯ"}
                      active={active}
                      setActive={setActive}
                      currentToggler={TogglerEnum.KITCHEN}
      />
      <TogglerElement text={"БАР"}
                      active={active}
                      setActive={setActive}
                      currentToggler={TogglerEnum.BAR}
      />
    </div>
  );
}

export default Toggler;