import {combineReducers} from "redux";
import pageReducer from "./pageReducer";
import adminReducer from "./adminReducer";
import basketReducer from "./basketReducer";
import togglerReducer from "./togglerReducer";
import productsReducer from "./productsReducer";
import modalKitchenReducer from "./modalKitchenReducer";
import paginationReducer from "./paginationReducer";
import orderReducer from "./orderReducer";
import posterReducer from "./posterReducer";
import menuReducer from "./menuReducer";

export const rootReducer = combineReducers({
    toggler: togglerReducer,
    products: productsReducer,
    modalKitchen: modalKitchenReducer,
    pagination: paginationReducer,
    order: orderReducer,
    page: pageReducer,
    adminPage: adminReducer,
    basket: basketReducer,
    poster: posterReducer,
    menu: menuReducer,
})

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>
