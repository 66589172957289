import {CreateOrderDto} from "../api/dto/CreateOrderDto";

export interface OrderAction {
    type: OrderActionType,
    order: CreateOrderDto
}

export enum OrderActionType {
    SET_ORDER = "SET_ORDER",
}

export const onSetOrder = (order: CreateOrderDto) => ({type: OrderActionType.SET_ORDER, order});
