import React, {useEffect, useState} from 'react';
import SelfTrack from '../components/form/SelfTrack'
import Header from "../components/header/Header";
import TitleWithBack from "../components/common/TitleWithBack";
import {ProductDto} from "../api/dto/GetProductDto";
import {NavLink} from "react-router-dom";
import Footer from "../components/common/footer/Footer";
import {Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/reducers";
import ApplicationEnum from "../utils/enums/ApplicationEnum";
import back from "../assests/images/arrow-left.svg";
import Lottie from "react-lottie-player"
import {BaseAPI} from "../api/api";
import preloader from '../assests/preloader.json';
import {onSetOrder} from "../actions/OrderAction";
import {basketClear, CountedProduct} from "../actions/BasketAction";
import ReactInputMask from "react-input-mask";
import usePage from "../utils/hooks/usePage";
import PageEnum from "../utils/enums/PageEnum";
import BasketForm from "../components/header/basket/BasketForm";

const Order = () => {

    const [wrongTel, setWrongTel] = useState(false);
    const [wrongName, setWrongName] = useState(false);
    const [wrongSurname, setWrongSurname] = useState(false);
    const basket = useSelector((state: RootState) => state.basket);
    const {order} = useSelector((state: RootState) => state.order);
    const dispatch = useDispatch();
    const {basketFabrika, basketBottle} = useSelector((state: RootState) => state.basket);
    const {application} = useSelector((state: RootState) => state.page);
    const [doneButtonDisabled, setDoneButtonDisabled] = useState(true);
    const [showLoading, setShowLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [next, setNext] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);

    usePage(PageEnum.ORDER);

    const createOrder = () => {
        setShowLoading(true);
        if (order.customer.name === "" || order.customer.surname === "" || order.customer.phone === "" || order.order.isSelfService ||
            (order.order.paymentEnum === "" || order.order.paymentEnum === "")) {
            return;
        }

        BaseAPI.createOrder(order).then(() => {
            setSuccess(true);
            setError(false);
            setShowLoading(false);
            dispatch(basketClear(application));
            setTimeout(() => {
                window.location.href = "/";
            }, 5000);
        }).catch(() => {
            setError(true);
            setSuccess(false);
            setShowLoading(false);
        })
    };

    let paymentName = null;
    let products = new Array<CountedProduct>();
    if (order.order.paymentEnum === "CARD") {
        paymentName = "Картой при получении"
    } else if (order.order.paymentEnum === "CASH") {
        paymentName = "Наличными при получении"
    }

    if (application === ApplicationEnum.FABRIKA) {
        products = basketFabrika.products;
    } else {
        products = basketBottle.products;
    }

    if (products.length === 0) {
        setTimeout(() => {
            window.location.href = "/menu"
        }, 2000)
    }

    return (
        <div className="container">
            <Header/>

            {products.length === 0 &&
                <div className={"basket_empty"}>
                    <div className={"basket_empty_message"}><strong>Закажите что-нибудь</strong></div>
                </div>
            }

            {products.length !== 0 && next && <div className="conditions__header" onClick={() => setNext(false)}>
                <div className="opacity06">
                    <div style={{display: "flex"}} className="change_data">
                        <img className="img" src={back} alt="back"/>
                        <p className="p">Изменить данные</p>
                        <p className={"p1"}>Назад</p>
                    </div>
                </div>
            </div>}
            {next && <div className="submit-data">
                <p className="submit-form__text">Заказ для</p>
                <div className="submit-data__persont-info">
                    <div className="name" id='name-value'>{order.customer.name} {order.customer.surname}</div>
                    <div className="number" id='number-value'>{order.customer.phone}</div>
                </div>
                <p className="submit-form__text">САМОВЫВОЗ</p>
                <address className='submit-data__text' id='address-value'>Курск, Ленина 77Б</address>
                <p className="submit-form__text">Способ оплаты</p>
                <div className="submit-data__text" id='payment-value'>{paymentName}</div>
                <input type="text" id='comment' name='comment' placeholder="Комментарий к заказу"
                       onChange={e => order.order.comment = e.target.value} defaultValue={order.order.comment}/>
                <p className="submit-data__price" id='price-value'>К оплате: {totalPrice} ₽ </p>

                {!success && <div className="submit-data__triggers">
                    <div className="submit-data__triggers_b">
                        <button onClick={createOrder}
                                type='submit'
                                className={`person-form__submit pointer ${doneButtonDisabled ? 'person-form__submit__disabled' : "person-form__submit__not_disabled"} ${showLoading ? "person-form__submit__loading" : ""}`}
                                disabled={doneButtonDisabled}
                        >
                            {showLoading ?
                                <div className={"loading"} style={{width: "50px", height: "50px"}}>
                                    <Lottie animationData={preloader} speed={1}
                                            style={{width: "50px", height: "50px"}} play loop/>
                                </div>
                                : "СОЗДАТЬ ЗАКАЗ"}
                        </button>

                        <div className="submit-data__policy">
                            <label onClick={() => setDoneButtonDisabled(!doneButtonDisabled)}>
                                <input name="checkbox" id="checkbox" type="checkbox" required/>
                                <span>Я согласен(а) с <NavLink
                                    to={"/order/conditions"}>условиями заказа</NavLink></span>
                            </label>
                        </div>
                    </div>
                </div>}
            </div>}
            {next && success && <div className="success">ЗАКАЗ СДЕЛАН<span className="checkmark">
                         <div className="checkmark_stem"></div>
                         <div className="checkmark_kick"></div>
                </span></div>}
            {next && success && <div style={{color: "green"}}><br/>Ожидайте звонка оператора</div>}
            {next && error && <div style={{color: "red"}}>OOPS, ошибка. Заказ не создан</div>}


            {products.length !== 0 && !next && <TitleWithBack title={""}
                                                              backName={"Вернуться к выбору"}
                                                              secondBackname={"Назад"}
                                                              backPath={"/menu"}/>}
            {products.length !== 0 && !next &&
                <Formik initialValues={{
                    name: order.customer.name,
                    surname: order.customer.surname,
                    tel: order.customer.phone
                }}
                        onSubmit={
                            (values) => {
                                let wrong = false;
                                order.customer.name = values.name;
                                order.customer.surname = values.surname;
                                order.customer.phone = values.tel;
                                order.order.applicationEnum = application;

                                const phone = order.customer.phone.replace(/[()-]/g, '').replace(/\s/g, '');
                                if (phone.substr(0, 1) !== "+" || !/^\d+$/.test(phone.substr(1)) || phone === "") {
                                    setWrongTel(true);
                                    wrong = true;
                                }
                                if (order.customer.name === "") {
                                    setWrongName(true);
                                    wrong = true;
                                }
                                if (order.customer.surname === "") {
                                    setWrongSurname(true);
                                    wrong = true;
                                }
                                if (!wrong) setNext(true);

                                products.map(item => {
                                    const product = {
                                        iikoId: item.product.iikoId,
                                        amount: item.amount,
                                        price: item.product.price
                                    }
                                    order.order.items.push(product);
                                })
                                setTotalPrice(application === ApplicationEnum.FABRIKA ? basketFabrika.price : basketBottle.price);
                                dispatch(onSetOrder(order));
                            }
                        }>
                    {({
                          handleChange,
                          handleSubmit
                      }) => (
                        <form className="person-form flex " onSubmit={handleSubmit}>
                            <div className="person-form__body flex">
                                <p className="person-form__text">Личные данные</p>

                                <div className="person-form__inputs">
                                    <div className={`${wrongName ? "false" : ""}`}>
                                        <input name='name' id='name' type="text" placeholder='Имя*'
                                               onChange={handleChange} onClick={() => setWrongName(false)}
                                               defaultValue={order.customer.name}/>
                                    </div>
                                    <div className={`${wrongSurname ? "false" : ""}`}>
                                        <input name='surname' id='surname' type='text'
                                               placeholder='Фамилия*'
                                               onChange={handleChange} onClick={() => setWrongSurname(false)}
                                               defaultValue={order.customer.surname}/>
                                    </div>
                                    <div className={`${wrongTel ? 'false' : ''}`}>
                                        <ReactInputMask mask={'+7 (999) 999-99-99'} name='tel' id='tel' type='tel'
                                                        placeholder='Телефон*'
                                                        onChange={handleChange} onClick={() => setWrongTel(false)}
                                                        defaultValue={order.customer.phone}/>
                                    </div>
                                </div>

                                <p className='person-form__text'>Способы получения</p>

                                <div className='person-form__delivery'>
                                    <button onClick={() => {
                                        order.order.isSelfService = false
                                        dispatch(onSetOrder(order));
                                    }} type='button'
                                            className={`person-form__btn pointer ${order.order.isSelfService ? '' : 'person-form__btn_active'}`}
                                            id='self-track'>
                                        С собой
                                    </button>
                                    <button type='button'
                                            className={`person-form__btn ${order.order.isSelfService ? 'person-form__btn_active' : ''} pointer`}
                                            id='delivery'>Доставка
                                    </button>
                                    <div className='warning'>Скоро будет доступно</div>
                                </div>

                                {/* You need to toggle next 2 object according to cliked button.person-form__btn in person-form__deliver block*/}
                                {
                                    !order.order.isSelfService && <SelfTrack/>
                                }

                                <div className='payment'>
                                    <div className={'card'} onClick={() => {
                                        order.order.paymentEnum = 'CARD';
                                        dispatch(onSetOrder(order));
                                    }}>
                                        <input type={'radio'} name={'payment'} id={'card'}
                                               checked={order.order.paymentEnum === 'CARD'}/>
                                        <label htmlFor={'card'}>Картой при получении</label>
                                    </div>
                                    <div className={'cash'} onClick={() => {
                                        order.order.paymentEnum = 'CASH';
                                        dispatch(onSetOrder(order));
                                    }}>
                                        <div className={'m_radio'}>
                                            <input type={'radio'} name={'payment'} id={'cash'}
                                                   checked={order.order.paymentEnum === 'CASH'}/>
                                            <label htmlFor={'cash'}>Наличными при получении</label>
                                        </div>
                                    </div>
                                </div>
                                <button className='person-form__next pointer' type={'submit'}>
                                    Далее
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>}
            <Footer/>
        </div>

    );
}

export default Order;