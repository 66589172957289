import React from 'react';
import Lottie from "react-lottie-player"

import preloader from '../../assests/preloader.json';

interface PreloaderProps {
  small: boolean,
  medium: boolean
}

const Preloader = ({small, medium}: PreloaderProps) => {
  return (
    <div className={`preloader ${small ? "preloader__small" : ""} ${medium ? "preloader__medium" : ""}`}>
      <Lottie animationData={preloader} speed={1} style={{width: "150px", height: "150px"}} play loop/>
    </div>
  );
};

export default Preloader;