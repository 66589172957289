import {useDispatch} from "react-redux";
import {switchPage} from "../../actions/PageAction";
import {useEffect} from "react";
import PageEnum from "../enums/PageEnum";

/**
 * Хук для диспатча информации текущей страницы в Store
 * @param page Текущая страница
 */
const usePage = (page: PageEnum): void => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(switchPage(page));

    return () => {
      dispatch(switchPage(PageEnum.NONE));
    }
  }, [page]);
}

export default usePage;