import React from 'react';
import deleteCross from "../../assests/images/deleteCross.svg";

interface CustomFileUploadProps {
  imageName?: string | undefined,
  newImageName: string,
  setNewImgFile: Function,
  setNewImageName: Function,
}

const ImgInput = ({imageName, newImageName, setNewImageName, setNewImgFile}: CustomFileUploadProps) => {

  const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;
    if (!fileList || !fileList[0]) {
      return;
    } else {
      const name = fileList[0].name;
      setNewImgFile(fileList[0]);
      setNewImageName(name);
    }
  };

  const deleteImg = () => {
    setNewImageName("");
    setNewImgFile();
  }

  return <div className="imgInput">
    {
      newImageName
          ? <><p>{newImageName}</p><img src={deleteCross} alt="delete cross" onClick={deleteImg}/></>
          : <p> "Файл изображения - jpg, png."</p>
    }
    <input
        accept="image/png, image/jpeg"
        name="image"
        type="file"
        multiple={false}
        onChange={handleChange}
    />
  </div>;
}

export default ImgInput;