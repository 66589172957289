import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ApplicationEnum from "../utils/enums/ApplicationEnum";
import {RootState} from "../store/reducers";
import PageEnum from "../utils/enums/PageEnum";
import {getPosters} from "../actions/PosterAction";
import Preloader from "../components/common/Preloader";
import HeaderFabrikaBlock from "../components/landing/fabrika/HeaderFabrikaBlock";
import HeaderLanding from "../components/landing/HeaderLanding";
import PosterContainer from "../components/landing/fabrika/posters/PosterContainer";
import FooterFabrikaBlock from "../components/landing/fabrika/FooterFabrikaBlock";
import Footer from "../components/common/footer/Footer";
import HeaderBottleBlock from "../components/landing/bootle/HeaderBottleBlock";
import FooterBottleBlock from "../components/landing/bootle/FooterBottleBlock";
import usePage from "../utils/hooks/usePage";

const Landing : React.FC = () => {

  const {application} = useSelector((state: RootState) => state.page);
  const [isPromoAnimation, setIsPromoAnimation] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  usePage(PageEnum.LANDING);

  useEffect(() => {
    const timeout = setTimeout(() => setIsLoading(false), 1000);

    return () => {
      clearTimeout(timeout);
    }
  }, [application]);

  useEffect(() => {
    if (application === ApplicationEnum.FABRIKA) {
      dispatch(getPosters());
    }
  });

  const style = (application === ApplicationEnum.FABRIKA && 'fabrika') || (application === ApplicationEnum.BOTTLE && 'bottle');

  if (isLoading) {
    return (
      <div className={`landing ${style}`}>
        <Preloader medium={false} small={false}/>
      </div>
    );
  }

  return (
    <>
      <div className={`landing ${style}`}>
        <HeaderLanding isPromoAnimation={isPromoAnimation}
                       setIsPromoAnimation={setIsPromoAnimation}
                       setIsLoading={setIsLoading}
        />
        {
          (application === ApplicationEnum.FABRIKA
            && <HeaderFabrikaBlock isPromoAnimation={isPromoAnimation} setIsLoading={setIsLoading}/>)
          ||
          (application === ApplicationEnum.BOTTLE
            && <HeaderBottleBlock isPromoAnimation={isPromoAnimation} setIsLoading={setIsLoading}/>)
        }
      </div>
      {
        application === ApplicationEnum.FABRIKA &&
          <>
            <div className="blur"/>
            <PosterContainer/>
          </>
      }
      <div className="blur"/>
      {
        (application === ApplicationEnum.FABRIKA && <FooterFabrikaBlock/>)
        ||
        (application === ApplicationEnum.BOTTLE && <FooterBottleBlock/>)
      }
      <Footer/>
    </>
  );
};

export default Landing;
