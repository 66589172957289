import React from 'react';
import MenuProductCard from "./MenuProductCard";
import {ProductResDto} from "../../../api/dto/ProductResDto";
import MenuNotFound from "./MenuNotFound";

interface Props {
  products: Array<ProductResDto> | null
}

const MenuProductPage: React.FC<Props> = ({products}) => {

  if (!products) {
    return <MenuNotFound/>;
  }

  return (
    <section className={"products"}>
      <div className={"products-body"}>
        {
          products.map(product => <MenuProductCard key={product.iikoId} product={product}/>)
        }
      </div>
    </section>
  );
}

export default MenuProductPage;