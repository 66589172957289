import React from 'react';
import BasketItem from "./BasketItem";
import {NavLink} from "react-router-dom";
import {RootState} from "../../../store/reducers";
import {useDispatch, useSelector} from "react-redux";
import ApplicationEnum from "../../../utils/enums/ApplicationEnum";
import {basketToggleActive} from "../../../actions/BasketAction";
import PageEnum from "../../../utils/enums/PageEnum";

const BasketForm = () => {

  const {basketFabrika, basketBottle, isActive} = useSelector((state: RootState) => state.basket);
  const {application,page} = useSelector((state: RootState) => state.page);
  const dispatch = useDispatch();

  if (!isActive) {
    return null;
  }

  let totalPrice = null;
  let products = null;
  if (application === ApplicationEnum.FABRIKA) {
    totalPrice = basketFabrika.price;
    products = basketFabrika.products;
  } else {
    totalPrice = basketBottle.price;
    products = basketBottle.products;
  }

  if (products.length === 0) {
    return <div className="error">
      <div className="basket-form__title">Мой заказ</div>
      <p>Здесь пока пусто:(</p>
      <p>Добавьте что-нибудь из меню, чтобы сделать заказ</p>
    </div>
  }

  function hideBasket() {
    dispatch(basketToggleActive(false))
  }

  return (
    <div className={`basket-form ${page===PageEnum.ORDER ? "basket-form_absolute" : "basket-form_other"}`}>
      <div className="container">

        <div className="basket-form__header">
          <div className="basket-form__title">Мой заказ</div>

          {products.length > 0 && <div className="basket-form__make-order">
            <div className="basket-form__price">Итого: {totalPrice} ₽</div>
            <NavLink to={"/order"} onClick={hideBasket}>
              <div className="makeOrderButton"><h1>ОФОРМИТЬ ЗАКАЗ</h1></div>
            </NavLink>
          </div>}
        </div>

        <div className="basketHeader">
          <p>МОЙ ЗАКАЗ</p>
        </div>
        {
          products.length > 0 && <div className="basket-form__list">
            {
              products.map((item, index) => <BasketItem key={index} product={item.product} amount={item.amount}/>)
            }
            </div>
        }

        {products.length > 0 && <div className="basketForm">
          <p>Итого: {totalPrice} ₽ </p>
          <NavLink to={"/order"} onClick={hideBasket}>
            <div className="makeOrderButton"><h1>ОФОРМИТЬ ЗАКАЗ</h1></div>
          </NavLink>
        </div>}
      </div>
    </div>
  );
}
export default BasketForm;