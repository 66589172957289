import React, {useEffect} from "react";
import {GroupResDto} from "../../../api/dto/GroupResDto";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/reducers";
import MenuGroupsItem from "./MenuGroupsItem";
import TogglerEnum from "../../../utils/enums/TogglerEnum";
import {
  menuFetchGroups,
  menuFetchGroupsWithProducts,
  menuFetchProductsPage,
  menuSetActiveGroup
} from "../../../actions/MenuAction";

interface Props {
  active: TogglerEnum
}

const MenuGroups: React.FC<Props> = ({active}) => {

  const {application} = useSelector((state: RootState) => state.page);
  const {baseGroups, activeGroup} = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(menuFetchGroups(application, active));
  }, [active]);

  useEffect(() => {
    if (!baseGroups || !baseGroups[0]) return;
    setActiveGroup(baseGroups[0]);
  }, [baseGroups]);

  useEffect(() => {
    const group = baseGroups?.find(group => group === activeGroup);
    if (!group) return;
    if (!group.hasSubgroups) {
      dispatch(menuFetchProductsPage(group.iikoId));
    } else {
      dispatch(menuFetchGroupsWithProducts(group.iikoId));
    }
  }, [activeGroup]);

  const setActiveGroup = (group: GroupResDto) => {
    dispatch(menuSetActiveGroup(group));
  }

  return (
    <div className={"groups"}>
      {
        baseGroups?.map(group =>
          <MenuGroupsItem
            key={group.iikoId}
            group={group}
            activeGroup={activeGroup}
            setActiveGroup={setActiveGroup}/>
        )
      }
    </div>
  );
}

export default MenuGroups;