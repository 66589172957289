import React, {useState} from 'react';
import AdminHeader from "./AdminHeader";
import AdminPanel from "./AdminPanel";
import AdminInfo from "./AdminInfo";
import {PosterResDto} from "../../api/dto/AuthReqDto";
import {AdminContentEnum} from "../../utils/enums/AdminContentEnum";

interface AdminProps {
  fetchPosters: () => void,
  posts: Array<PosterResDto>,
}

const Admin = ({posts, fetchPosters}: AdminProps) => {

  const [content, setContent] = useState(AdminContentEnum.POSTERS);

  return (
      <div className="admin">
        <AdminHeader postersNumber={posts.length} content={content} setContent={setContent}/>
        {
          content === AdminContentEnum.INFO && <AdminInfo/>
        }
        {
          content === AdminContentEnum.POSTERS && <AdminPanel posts={posts} fetchPosters={fetchPosters}/>
        }
      </div>
  );
}

export default Admin;